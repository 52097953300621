import { useEffect, useState } from 'react';
import {
  Link,
  redirect,
  useNavigate,
  useOutletContext,
  useResolvedPath,
} from 'react-router-dom';

import {
  consistencyType,
  testErrorType,
  getLocalStorage,
  localStorageType,
  getTestErrors,
  setTestErrors,
  getTestByName,
} from 'engine/localStorageHelper';
import { StatusCodes, StatusManager } from 'engine/models';
import {
  correctSingleErrorPromise,
  viewErrorPromise,
  finalizeDocumentConsistencyPromise,
  rescanAllTestsErrorsPromise,
  rescanSingleTestErrorsPromise,
} from 'app/office-document';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Consistency/Consistency.scss';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import Button from 'components/Button/Button';
import TabHeader from 'components/TabHeader/TabHeader';
import ConsistencyItem from 'components/ConsistencyItem/ConsistencyItem';
import Progress from 'components/Progress/Progress';
import { UIStrings } from 'app/UIStrings';
import { getConsistencyByTestKey } from 'engine/consistency';

const Consistency = () => {
  const navigate = useNavigate();

  const resolved = useResolvedPath(`/index.html/final`);

  const [errors, setErrors] = useState<consistencyType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => {
      setStatusMessage(message);

      if (message === UIStrings.statusbar.report_generated) {
        // TODO: implement current task key.
        return navigate('/index.html/final', { replace: true });
      }
    },
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success) {
        const testErrors = getLocalStorage().consistency;
        updateStatus(testErrors);
        setErrors(testErrors);
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  /**
   * Effect hook to initialize errors from local storage.
   * This effect runs once when the component mounts.
   */
  useEffect(() => {
    const localStorage: localStorageType = getLocalStorage();
    const errors: consistencyType[] = localStorage.consistency;

    setErrors(errors);
  }, []);

  /**
   * Rescans errors for a single test.
   * @param testName - The name of the test to rescan.
   */
  const rescanSingleTestErrors = (testName: string) => {
    rescanSingleTestErrorsPromise(statusManager, testName);
  };

  /**
   * Rescans errors for all tests.
   */
  const rescanAllTestsErrors = () => {
    rescanAllTestsErrorsPromise(statusManager, false);
  };

  /**
   * Finalizes the document consistency.
   */
  const finalize = () => {
    finalizeDocumentConsistencyPromise(statusManager);
  };

  /**
   * Accepts and corrects a test error.
   * @param testName - The name of the test containing the error.
   * @param testErrorToFix - The error to be fixed.
   */
  const acceptTestError = (testName: string, testErrorToFix: testErrorType) => {
    if (testErrorToFix.skip) return;

    const testErrors = getTestErrors(testName);
    const indexOfError = testErrors.findIndex(
      (error) => error.id === testErrorToFix.id
    );

    const newTestErrors = testErrors;
    newTestErrors.splice(indexOfError, 1);

    const consItem = getConsistencyByTestKey(testName);

    updateErrors(testName, newTestErrors);
    setTestErrors(testName, consItem.testDisplayName, newTestErrors);

    correctSingleErrorPromise(statusManager, testErrorToFix);
  };

  /**
   * Jumps to the location of a specific error in the document.
   * @param testErrorToFix - The error to jump to.
   */
  const jumpToError = (testErrorToFix: testErrorType) => {
    if (testErrorToFix.skip) return;
    viewErrorPromise(statusManager, testErrorToFix);
  };

  /**
   * Marks a test error as skipped and updates the error list.
   * @param testName - The name of the test containing the error.
   * @param testErrorToSkip - The error to be skipped.
   * @param skipReason - The reason for skipping the error.
   */
  const skipTestError = (
    testName: string,
    testErrorToSkip: testErrorType,
    skipReason: string
  ) => {
    if (testErrorToSkip.skip) return;

    const testErrors = getTestErrors(testName);
    const indexOfError = testErrors.findIndex(
      (error) => error.id === testErrorToSkip.id
    );

    const newTestErrors = testErrors;
    newTestErrors.splice(indexOfError, 1, {
      ...testErrorToSkip,
      skip: true,
      skipReason: skipReason.trim(),
    });

    const consItem = getConsistencyByTestKey(testName);

    updateErrors(testName, newTestErrors);
    setTestErrors(testName, consItem.testDisplayName, newTestErrors);
  };

  /**
   * Updates the status with the count of non-skipped test errors.
   * @param testErrorsToUpdateStatus - Array of consistency type objects containing test errors.
   * @returns The updated status object.
   */
  const updateStatus = (testErrorsToUpdateStatus: consistencyType[]) => {
    const testErrorsWithoutSkipped = getErrorsCount(testErrorsToUpdateStatus);
    return setStatus((prevStatus) => {
      return {
        ...prevStatus,
        consistencyErrorsCount: testErrorsWithoutSkipped,
      };
    });
  };

  /**
   * Counts the number of non-skipped errors across all tests.
   * @param testErrorsToGetErrorsCount - Array of consistency type objects containing test errors.
   * @returns The total count of non-skipped errors.
   */
  const getErrorsCount = (testErrorsToGetErrorsCount: consistencyType[]) => {
    let errorsCount = 0;
    testErrorsToGetErrorsCount.forEach((test) => {
      const testErrorsWithoutSkipped = test.testErrors.filter(
        (error) => !error.skip
      );
      errorsCount += testErrorsWithoutSkipped.length;
    });

    return errorsCount;
  };

  /**
   * Updates the errors for a specific test and updates the global error state.
   * 
   * @param testName - The name of the test to update errors for.
   * @param testErrors - An array of new test errors to replace the existing ones.
   * 
   * @remarks
   * This function updates the errors for a specific test, updates the global error state,
   * and then calls updateStatus to refresh the error count in the UI.
   */
  const updateErrors = (testName: string, testErrors: testErrorType[]) => {
    const newErrors = errors.map((test) => {
      if (test.testName === testName) {
        return {
          ...test,
          testErrors: testErrors,
        };
      }
      return test;
    });

    setErrors(newErrors);
    updateStatus(newErrors);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        {errors.length > 0 &&
        errors.findIndex((testError) => testError.testName === 'references') >=
          0 ? (
          <>
            <TabHeader
              title={UIStrings.consistency_global.consistency_title}
              description={`${UIStrings.consistency_global.consistency_description_1} ${getErrorsCount(errors)} ${UIStrings.consistency_global.consistency_description_2}`}
              color="black"
              errors={getErrorsCount(errors)}
              refresh={rescanAllTestsErrors}
            />

            <div className={classes.errorsContainer}>
              {errors.map((test: consistencyType) => (
                <ConsistencyItem
                  key={test.testName}
                  testName={test.testName}
                  testTitle={test.testTitle}
                  testErrors={test.testErrors}
                  rescanTestErrors={rescanSingleTestErrors}
                  acceptTestError={acceptTestError}
                  jumpToError={jumpToError}
                  skipTestError={skipTestError}
                />
              ))}
            </div>

            <div className={classes.finalizeDocumentButton}>
              <Button
                disabled={!!getErrorsCount(errors)}
                title={UIStrings.consistency_global.finalize_document}
                color="blue"
                size="big"
                onClick={() => finalize()}
              />
            </div>
          </>
        ) : (
          <div className={classes.noTestErrorsContainer}>
            <div className={classes.actualizeImage}>
              <img
                src="../../../../assets/actualize.svg"
                alt="Actualize Test Errors"
              />
            </div>
            <div className={classes.actualizeTestErrors}>
              <div>{UIStrings.consistency_global.consistency_check}</div>
              <Button
                title={UIStrings.consistency_global.refresh}
                color="blue"
                size="big"
                onClick={() => rescanAllTestsErrors()}
              />
            </div>
          </div>
        )}

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
        />
      </div>
    </div>
  );
};

export default Consistency;
