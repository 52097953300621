import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  checkMultipleDefinitionsPromise,
  refreshDefinitionsPromise,
  saveDefinitionInDbPromise,
  uncheckDefinitionPromise,
} from 'app/office-document';
import {
  definitionType,
  getLocalStorage,
  updateDefinitions,
} from 'engine/localStorageHelper';
import { StatusCodes, StatusManager } from 'engine/models';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Definition/Definition.scss';
import TabHeader from 'components/TabHeader/TabHeader';
import Search from 'components/Search/Search';
import Button from 'components/Button/Button';
import DefinitionItem from 'components/DefinitionItem/DefinitionItem';
import Progress from 'components/Progress/Progress';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import { UIStrings } from 'app/UIStrings';

const Definition = () => {
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(false);
  const [definitions, setDefinitions] = useState<definitionType[]>([]);
  const [checkedDefinitions, setCheckedDefinitions] = useState<
    definitionType[]
  >([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<'add' | 'check' | 'uncheck'>(
    null
  );

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success || code === StatusCodes.Fail) {
        const definitions = getLocalStorage().definitions;
        setDefinitions(definitions);
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  /**
   * Effect hook to update progress when status code changes.
   */
  useEffect(() => {
    if (statusCode === StatusCodes.Success || statusCode === StatusCodes.Fail) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  /**
   * Updates the progress status based on the type of action.
   * @param {('add' | 'check' | 'uncheck')} type - The type of action performed.
   * @returns {void}
   */
  const updateProgress = (type: 'add' | 'check' | 'uncheck') => {
    const checkedDefinitions = definitions.filter(
      (definition) => definition.checked
    );

    if (type === 'add') {
      return setStatus((prevStatus) => {
        if (checkedDefinitions.length < 1) {
          return prevStatus;
        }

        return { ...prevStatus, definitions: 100 };
      });
    }

    if (type === 'check') {
      return setStatus((prevStatus) => {
        if (prevStatus.definitions === 100) {
          return prevStatus;
        }

        return { ...prevStatus, definitions: 50 };
      });
    }

    if (type === 'uncheck') {
      return setStatus((prevStatus) => {
        if (checkedDefinitions.length > 0) {
          return prevStatus;
        }

        return { ...prevStatus, definitions: 0 };
      });
    }
  };

  /**
   * Effect hook to initialize definitions from local storage.
   */
  useEffect(() => {
    const localStorage = getLocalStorage();
    const definitions = localStorage.definitions;

    if (definitions.length > 0) {
      setDefinitions(definitions);
    }
  }, []);

  /**
   * Refreshes the definitions using the refresh promise.
   */
  const refresh = () => {
    refreshDefinitionsPromise(statusManager);
  };

  /**
   * Handles the checking of a definition item.
   * @param {definitionType} definitionToCheck - The definition to be checked.
   */
  const onItemCheck = (definitionToCheck: definitionType) => {
    updateProgress('check');

    if (checkCheckedDuplicateDefinition(definitionToCheck.title)) {
      updateCheckedDefinitions(
        definitionToCheck.title,
        definitionToCheck.description
      );
    } else {
      setCheckedDefinitions([...checkedDefinitions, definitionToCheck]);
    }
  };

  /**
   * Handles the unchecking of a definition item.
   * @param {definitionType} definitionToUnCheck - The definition to be unchecked.
   */
  const onItemUnCheck = (definitionToUnCheck: definitionType) => {
    const originalDefinition = definitions.find((definition) => {
      return definition.title === definitionToUnCheck.title;
    });

    setCheckedDefinitions((checkedDefinitions) => {
      const newCheckedDefinitions = checkedDefinitions.filter(
        (checkedDefinition) =>
          checkedDefinition.title !== definitionToUnCheck.title
      );

      if (newCheckedDefinitions.length === 0) {
        updateProgress('uncheck');
      }

      return newCheckedDefinitions;
    });

    if (originalDefinition.checked !== definitionToUnCheck.checked) {
      return;
    }

    setTypeOfAction('uncheck');
    uncheckDefinitionPromise(statusManager, definitionToUnCheck);
  };

  /**
   * Updates the description of a checked definition and marks it as checked.
   * @param {string} title - The title of the definition to update.
   * @param {string} newDescription - The new description to set for the definition.
   * @returns {void}
   */
  const updateCheckedDefinitions = (title: string, newDescription: string) => {
    const newCheckedDefinitions = checkedDefinitions.map((definition) => {
      if (definition.title === title) {
        return {
          ...definition,
          checked: true,
          description: newDescription,
        };
      }

      return definition;
    });

    setCheckedDefinitions(newCheckedDefinitions);
  };

  /**
   * Processes checked definitions, updates their status, and clears the checked list.
   * @returns {void}
   */
  const checkDefinitions = () => {
    checkMultipleDefinitionsPromise(statusManager, checkedDefinitions);
    setTypeOfAction('add');
    setCheckedDefinitions([]);
  };

  /**
   * Adds a new definition to the list of definitions.
   * @param {string} title - The title of the new definition.
   * @param {string} description - The description of the new definition.
   * @returns {void}
   */
  const addDefinition = (title: string, description: string) => {
    const newDefinitions: definitionType[] = [
      ...definitions,
      {
        title: title.trim(),
        parentText: '',
        description: description.trim(),
        checked: false,
        changed: false,
        custom: true,
      },
    ];

    updateDefinitionsInLocalStorage(newDefinitions);
  };

  /**
   * Creates a new custom definition with the given title and adds it to the list of definitions.
   * @param {string} title - The title of the new custom definition.
   * @returns {void}
   */
  const createCustomDefinition = (title: string) => {
    const newDefinitions: definitionType[] = [
      ...definitions,
      {
        title: title.trim(),
        parentText: '',
        description: 'Beschreibung',
        checked: false,
        changed: false,
        custom: true,
      },
    ];

    updateDefinitionsInLocalStorage(newDefinitions);
  };

  /**
   * Updates an existing definition with new title and description.
   * @param {definitionType} definitionToUpdate - The definition object to be updated.
   * @param {string} newTitle - The new title for the definition.
   * @param {string} newDescription - The new description for the definition.
   * @returns {void}
   */
  const updateDefinition = (
    definitionToUpdate: definitionType,
    newTitle: string,
    newDescription: string
  ) => {
    const indexOfDefinition = definitions.findIndex(
      (definition) => definition.title === definitionToUpdate.title
    );

    const newDefinitions = definitions;
    newDefinitions.splice(indexOfDefinition, 1, {
      ...definitionToUpdate,
      title: newTitle.trim(),
      description: newDescription.trim(),
    });

    updateDefinitionsInLocalStorage(newDefinitions);
  };

  /**
   * Updates the definitions in local storage and in the component state.
   * @param {definitionType[]} newDefinitions - The new array of definitions to be stored.
   * @returns {void}
   */
  const updateDefinitionsInLocalStorage = (
    newDefinitions: definitionType[]
  ) => {
    setDefinitions(newDefinitions);
    updateDefinitions(newDefinitions);
  };

  /**
   * Saves a definition to the database using a promise-based function.
   * @param {definitionType} definition - The definition to be saved in the database.
   * @returns {void}
   */
  const updateDefinitionInDB = (definition: definitionType) => {
    saveDefinitionInDbPromise(statusManager, definition);
  };

  /**
   * Checks if a definition with the given title already exists.
   * @param {string} newTitle - The title to check for duplicates.
   * @returns {boolean} True if a duplicate is found, false otherwise.
   */
  const checkDuplicateDefinition = (newTitle: string) => {
    const indexOfDuplicateDefinition = definitions.findIndex(
      (definition) => definition.title === newTitle
    );

    return indexOfDuplicateDefinition > -1;
  };

  /**
   * Checks if a definition with the given title already exists in the checked definitions.
   * 
   * @param {string} title - The title of the definition to check for duplicates.
   * @returns {boolean} Returns true if a duplicate is found, false otherwise.
   */
  const checkCheckedDuplicateDefinition = (title: string) => {
    const indexOfDuplicateDefinition = checkedDefinitions.findIndex(
      (definition) => definition.title === title
    );

    return indexOfDuplicateDefinition > -1;
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.definitions.definitions_title}
          description={UIStrings.definitions.definitions_description}
          color="black"
          hasProgress={true}
          progress={status.definitions}
          refresh={refresh}
        />
        <Search
          placeholder={UIStrings.definitions.db_search}
          addDefinition={addDefinition}
        />
        {definitions.length > 0 &&
          definitions.map((definition: definitionType) => (
            <DefinitionItem
              key={definition.title}
              definition={definition}
              onItemCheck={onItemCheck}
              onItemUnCheck={onItemUnCheck}
              updateDefinition={updateDefinition}
              updateDefinitionInDB={updateDefinitionInDB}
              checkDuplicateDefinition={checkDuplicateDefinition}
              updateCheckedDefinitions={updateCheckedDefinitions}
            />
          ))}

        {isNew && (
          <DefinitionItem
            definition={{
              title: '',
              description: '',
              parentText: '',
              checked: false,
              changed: false,
              custom: true,
            }}
            isNew={isNew}
            createCustomDefinition={createCustomDefinition}
            close={() => setIsNew(false)}
            checkDuplicateDefinition={checkDuplicateDefinition}
          />
        )}
        <Button
          title={UIStrings.definitions.definition_add}
          color="transparent"
          size="big"
          icon="add"
          onClick={() => setIsNew(true)}
        />

        <div className={classes.addToDocumentButton}>
          <Button
            disabled={checkedDefinitions.length === 0}
            title={UIStrings.definitions.add_to_document}
            color="blue"
            size="big"
            onClick={checkDefinitions}
          />
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
        />
      </div>
    </div>
  );
};

export default Definition;
