import { tempStorage } from 'app/index';
import {
  LoginResponse,
  UserInfoResponse,
  defaultLoginResponse,
  defaultUserInfoResponse,
} from './models';
import {
  consistencyType,
  defaultUser,
  getUserLocalStorage,
  statusType,
  testErrorType,
  updateUserLocalStorage,
} from './localStorageHelper';
import {
  FetchAbstractResponse,
  FetchAllDistancesResponse,
  FetchCommonResponse,
  FetchGenerateReportResponse,
  FetchGetAllTermsResponse,
  FetchGetConsistencyListResponse,
  FetchGetTemplatesListResponse,
  FetchGetTermResponse,
  FetchParallelConsistencyResponse,
  FetchReferencesResponse,
  FetchRootsResponse,
  FetchSearchStemmedResponse,
  FetchTemplateDocumentContentResponse,
  FetchTermLookupResponse,
  FetchTermsDescriptionsResponse,
  FetchUpdateTermResponse,
  defaultFetchAllDistancesResponse,
  defaultFetchCommonResponse,
  defaultFetchAbstractResponse,
  defaultFetchGenerateReportResponse,
  defaultFetchGetAllTermsResponse,
  defaultFetchGetConsistencyListResponse,
  defaultFetchGetTemplatesListResponse,
  defaultFetchGetTermResponse,
  defaultFetchParallelConsistencyResponse,
  defaultFetchReferencesResponse,
  defaultFetchRootsResponse,
  defaultFetchSearchStemmedResponse,
  defaultFetchTemplateDocumentContentResponse,
  defaultFetchTermLookupResponse,
  defaultFetchTermsDescriptionsResponse,
  defaultFetchUpdateTermResponse,
} from './apiModels';

const DISTANCES_FOUND_PERCENT_THRESHOLD: number = 0.95;

/**
 * Fetches a list of templates from the server using an authorized fetch request.
 *
 * @returns {Promise<FetchGetTemplatesListResponse>} - A promise that resolves to the response object containing the list of templates.
 * The response object includes the following properties:
 * - `templates`: An array of template objects.
 * - `error`: A string representing any error that occurred during the fetch request.
 * - `httpCode`: The HTTP status code of the response.
 */
const fetchGetTemplatesList = async () => {
  let resp: FetchGetTemplatesListResponse =
    defaultFetchGetTemplatesListResponse;

  const response = await authorizedFetch('GET', '/api/templates', '');

  if (response.ok) {
    const responseObject: FetchGetTemplatesListResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Fetches the content of a template document from the server.
 *
 * @param templateName - The name of the template to fetch. If provided, a '/' is prepended to it.
 * @returns A promise that resolves to a FetchTemplateDocumentContentResponse object containing:
 *   - template: The base64-encoded content of the template document.
 *   - error: A string describing any error that occurred during the fetch.
 *   - httpCode: The HTTP status code of the response.
 */
const fetchTemplateDocumentContent = async (templateName: string) => {
  if (templateName) {
    templateName = '/' + templateName;
  }
  let resp: FetchTemplateDocumentContentResponse =
    defaultFetchTemplateDocumentContentResponse;
  const templateDocumentUrl =
    '/api/template' + templateName + '?' + Math.floor(Math.random() * 1000);

  let base64Content: string = '';
  const response = await authorizedFetch('GET', templateDocumentUrl, '');
  resp.httpCode = response.status;
  if (response.ok) {
    const data = await response.arrayBuffer();
    base64Content = btoa(
      new Uint8Array(data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
    resp.template = base64Content;
  } else {
    resp.error = 'response error code:' + response.status;
    console.error(resp.error);
  }

  return resp;
};

/**
 * Fetches distances between a main string and an array of secondary strings.
 *
 * @param main - The main string to compare against.
 * @param secondary - An array of secondary strings to compare with the main string.
 * @returns A Promise that resolves to a FetchAllDistancesResponse object containing:
 *   - distances: An array of calculated distances between the main string and each secondary string.
 *   - error: A string describing any error that occurred during the fetch operation.
 *   - httpCode: The HTTP status code of the response.
 */
const fetchDistances = async (main: string, secondary: string[]) => {
  const body = JSON.stringify({ main: main, secondary: secondary });
  let result: FetchAllDistancesResponse = {
    ...defaultFetchAllDistancesResponse,
  };

  const response = await authorizedFetch('POST', '/api/calcdist', body);

  if (response.ok) {
    const responseObject: FetchAllDistancesResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      result.error = 'response object error';
      console.error(result.error);
    } else {
      result = responseObject;
    }
  } else {
    result.error = 'response error code:' + response.status;
    console.error(result.error);
  }

  result.httpCode = response.status;

  return result;
};

/**
 * Fetches distances between all pairs of paragraphs and descriptions.
 *
 * @param paragraphs - An array of strings representing the paragraphs to compare.
 * @param descriptions - An array of strings representing the descriptions to compare against the paragraphs.
 * @returns A Promise that resolves to a FetchAllDistancesResponse object containing:
 *   - distances: An array of calculated distances between all pairs of paragraphs and descriptions.
 *   - error: A string describing any error that occurred during the fetch operation.
 *   - httpCode: The HTTP status code of the response.
 */
const fetchAllDistances = async (
  paragraphs: string[],
  descriptions: string[]
) => {
  const body = JSON.stringify({
    paragraphs: paragraphs,
    descriptions: descriptions,
  });
  let result: FetchAllDistancesResponse = {
    ...defaultFetchAllDistancesResponse,
  };

  const response = await authorizedFetch('POST', '/api/calcalldist', body);

  if (response.ok) {
    const responseObject: FetchAllDistancesResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      result.error = 'response object error';
      console.error(result.error);
    } else {
      result = responseObject;
    }
  } else {
    result.error = 'response error code:' + response.status;
    console.error(result.error);
  }

  result.httpCode = response.status;

  return result;
};

/**
 * Fetches term descriptions for a given input text.
 *
 * This function sends a POST request to the '/api/termsfill' endpoint with the input text
 * and retrieves term descriptions. It also updates the total terms and original terms
 * in temporary storage.
 *
 * @param inputText - The text for which term descriptions are to be fetched.
 * @returns A Promise that resolves to a FetchTermsDescriptionsResponse object containing:
 *   - terms: An array of term objects with their descriptions.
 *   - totalTerms: The total number of terms processed.
 *   - origTerms: The original terms found in the input text.
 *   - error: A string describing any error that occurred during the fetch operation.
 *   - httpCode: The HTTP status code of the response.
 */
const fetchTermsDescriptions = async (inputText: string) => {
  const body = JSON.stringify({
    Paragraphs: [inputText],
    ExcludeTerms: tempStorage.totalTerms,
    IncludeSource: false,
  });
  let resp: FetchTermsDescriptionsResponse =
    defaultFetchTermsDescriptionsResponse;

  const response = await authorizedFetch('POST', '/api/termsfill', body);

  if (response.ok) {
    const responseObject: FetchTermsDescriptionsResponse =
      await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
      tempStorage.totalTerms = responseObject.totalTerms;
      tempStorage.origTerms = responseObject.origTerms;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Performs a term lookup operation on the provided paragraphs.
 *
 * This function sends a POST request to the '/api/termlookup' endpoint with the given paragraphs and term.
 * It then processes the response and returns the result of the term lookup operation.
 *
 * @param paragraphs - An array of strings representing the paragraphs to search for the term.
 * @param term - A string representing the term to look up in the paragraphs.
 * @returns A Promise that resolves to a FetchTermLookupResponse object containing:
 *   - The result of the term lookup operation.
 *   - Any error message if the operation failed.
 *   - The HTTP status code of the response.
 */
const fetchTermLookup = async (paragraphs: string[], term: string) => {
  const body = JSON.stringify({
    Paragraphs: paragraphs,
    Term: term,
  });
  let result: FetchTermLookupResponse = { ...defaultFetchTermLookupResponse };

  const response = await authorizedFetch('POST', '/api/termlookup', body);

  if (response.ok) {
    const responseObject: FetchTermLookupResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      result.error = 'response object error';
      if (responseObject) {
        result.error = responseObject.error;
      }
      console.error(result.error);
    } else {
      result = responseObject;
    }
  } else {
    result.error =
      'response error code:' + response.status + ':' + (await response.text());
    console.error(result.error);
  }

  result.httpCode = response.status;

  return result;
};

/**
 * Fetches all terms from the server.
 *
 * This function sends a GET request to the '/api/terms' endpoint to retrieve all terms.
 * It handles the response, including any potential errors, and sets the HTTP status code.
 *
 * @returns {Promise<FetchGetAllTermsResponse>} A promise that resolves to a FetchGetAllTermsResponse object.
 *   The object contains:
 *   - terms: An array of term objects (if the request was successful)
 *   - error: A string describing any error that occurred
 *   - httpCode: The HTTP status code of the response
 */
const fetchGetAllTerms = async () => {
  let resp: FetchGetAllTermsResponse = { ...defaultFetchGetAllTermsResponse };

  const response = await authorizedFetch('GET', '/api/terms', '');

  if (response.ok) {
    const responseObject: FetchGetAllTermsResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Retrieves a filtered list of terms and their descriptions from Strapi.
 *
 * This function sends a GET request to the '/api/termsfilter/' endpoint with the provided keyword
 * to retrieve a filtered list of terms. It handles the response, including any potential errors,
 * and sets the HTTP status code.
 *
 * @param keyword - The keyword used to filter the terms.
 * @returns A promise that resolves to a FetchGetAllTermsResponse object containing:
 *   - terms: An array of filtered term objects (if the request was successful)
 *   - error: A string describing any error that occurred
 *   - httpCode: The HTTP status code of the response
 */
const fetchGetTermsFiltered = async (keyword: string) => {
  let resp: FetchGetAllTermsResponse = { ...defaultFetchGetAllTermsResponse };

  const response = await authorizedFetch(
    'GET',
    '/api/termsfilter/' + keyword,
    ''
  );

  if (response.ok) {
    const responseObject: FetchGetAllTermsResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Retrieves a term and its description from Strapi.
 *
 * This function sends a GET request to the '/api/terms/{term}' endpoint to retrieve
 * information about a specific term. It handles the response, including any potential
 * errors, and sets the HTTP status code.
 *
 * @param term - The specific term to fetch from the server.
 * @returns A Promise that resolves to a FetchGetTermResponse object containing:
 *   - The details of the requested term (if the request was successful)
 *   - An error message (if any error occurred)
 *   - The HTTP status code of the response
 */
const fetchGetTerm = async (term: string) => {
  let resp: FetchGetTermResponse = { ...defaultFetchGetTermResponse };

  const response = await authorizedFetch('GET', '/api/terms/' + term, '');

  if (response.ok) {
    const responseObject: FetchGetTermResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Updates the description of a specified term.
 *
 * This function sends a PUT request to update the description of a given term.
 * It handles the API response and returns the result of the update operation.
 *
 * @param term - The term whose description is to be updated.
 * @param description - The new description to be set for the term.
 * @returns A Promise that resolves to a FetchUpdateTermResponse object containing:
 *   - The result of the update operation.
 *   - Any error message if the operation failed.
 *   - The HTTP status code of the response.
 */
const fetchUpdateDescription = async (term: string, description: string) => {
  const body = JSON.stringify({
    Term: term,
    Description: description,
  });

  let resp: FetchUpdateTermResponse = { ...defaultFetchUpdateTermResponse };

  const response = await authorizedFetch('PUT', '/api/term', body);

  if (response.ok) {
    const responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Fetches references from the provided text using a common API endpoint with GPT.
 *
 * This function sends the input text to a common API endpoint configured for
 * reference extraction. It processes the response and returns a structured
 * object containing the extracted references or any error that occurred.
 *
 * @param text - The input text from which to extract references.
 * @returns A Promise that resolves to a FetchReferencesResponse object containing:
 *   - references: An array of extracted references, if successful.
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchReferences = async (text: string) => {
  const resp: FetchReferencesResponse = { ...defaultFetchReferencesResponse };
  const commonResp: FetchCommonResponse = await fetchCommon(
    text,
    'references',
    'gpt',
    false
  );

  if (commonResp) {
    if (!commonResp.error) {
      resp.references = commonResp.data.split(',').map((item) => item.trim());
    } else {
      resp.error = commonResp.error;
    }
  } else {
    resp.error = 'fetchReferences error';
  }

  resp.httpCode = commonResp.httpCode;

  return resp;
};

/**
 * Performs a stemmed search on the provided paragraphs using keywords and their root forms.
 *
 * This function sends a POST request to the '/api/searchstem' endpoint with the provided
 * paragraphs, keywords, and their root forms. It then processes the response and returns
 * the search results.
 *
 * @param paragraphs - An array of strings representing the paragraphs to search within.
 * @param keywords - An array of strings representing the keywords to search for.
 * @param roots - An array of strings representing the root forms of the keywords.
 * @returns A Promise that resolves to a FetchSearchStemmedResponse object containing:
 *   - The search results (if successful)
 *   - Any error message if the operation failed
 *   - The HTTP status code of the response
 */
const fetchSearchStemmed = async (
  paragraphs: string[],
  keywords: string[],
  roots: string[]
) => {
  let resp: FetchSearchStemmedResponse = {
    ...defaultFetchSearchStemmedResponse,
  };
  const body = JSON.stringify({
    paragraphs: paragraphs,
    keywords: keywords,
    roots: roots,
  });

  const response = await authorizedFetch('POST', '/api/searchstem', body);

  if (response.ok) {
    const responseObject: FetchSearchStemmedResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

/**
 * Tests a single claim for consistency using a specified test.
 *
 * This function processes a given claim, removing any tab characters, and then
 * sends it to the consistency checking service. It handles the response and
 * returns the result of the consistency test.
 *
 * @param claim - The claim text to be tested. Any tab characters will be replaced with spaces.
 * @param testName - The name of the consistency test to be applied to the claim.
 * @returns A Promise that resolves to a FetchCommonResponse object containing:
 *   - data: The result of the consistency test (if successful)
 *   - error: A string describing any error that occurred during the operation
 *   - httpCode: The HTTP status code of the response
 */
const fetchTestSingleClaim = async (claim: string, testName: string) => {
  const resp: FetchCommonResponse = { ...defaultFetchCommonResponse };

  const filteredClaim = claim.replaceAll('\t', ' ');

  const commonResp: FetchCommonResponse = await fetchConsistency(
    filteredClaim,
    testName,
    'gpt',
    false
  );

  if (commonResp) {
    if (!commonResp.error) {
      resp.data = commonResp.data;
    } else {
      resp.error = commonResp.error;
    }
  } else {
    resp.error = 'fetch test claims error';
  }

  resp.httpCode = commonResp.httpCode;

  return resp;
};

/**
 * Performs parallel consistency tests on multiple claims.
 *
 * This function takes an array of claims, filters out tab characters,
 * and sends them for parallel consistency checking. It processes the
 * response and returns the results of the consistency tests.
 *
 * @param claims - An array of strings representing the claims to be tested.
 * @param testName - A string specifying the name of the consistency test to be applied.
 * @returns A Promise that resolves to a FetchParallelConsistencyResponse object containing:
 *   - items: An array of consistency test results for each claim (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the response.
 */
const fetchParallelTestClaims = async (claims: string[], testName: string) => {
  const resp: FetchParallelConsistencyResponse = {
    ...defaultFetchParallelConsistencyResponse,
  };

  const filteredClaims: string[] = [];
  for (let i = 0; i < claims.length; i++) {
    const filteredClaim = claims[i].replaceAll('\t', ' ');
    filteredClaims.push(filteredClaim);
  }

  const parResp: FetchParallelConsistencyResponse =
    await fetchParallelClaimsConsistency(
      filteredClaims,
      testName,
      'gpt',
      false
    );

  if (parResp) {
    if (!parResp.error) {
      resp.items = [];
      parResp.items.forEach((item) => {
        resp.items.push(item);
      });
    } else {
      resp.error = parResp.error;
    }
  } else {
    resp.error = 'fetch test claims error';
  }

  resp.httpCode = parResp.httpCode;

  return resp;
};

/**
 * Fetches a figure based on the provided title, description, and related claims.
 *
 * This function constructs a text representation of the figure and its associated claims,
 * then sends it to a common API endpoint for processing. It handles the response and
 * returns a structured object containing the processed figure data or any error that occurred.
 *
 * @param figureTitle - The title of the figure to be processed.
 * @param figureDescription - A detailed description of the figure.
 * @param claims - An array of strings representing the claims related to the figure.
 * @returns A Promise that resolves to a FetchCommonResponse object containing:
 *   - data: The processed figure data (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchFigure = async (
  figureTitle: string,
  figureDescription: string,
  claims: string[]
) => {
  const resp: FetchCommonResponse = { ...defaultFetchCommonResponse };

  let text = 'Figure title: "' + figureTitle + '"\n';
  text += 'Figure description: "' + figureDescription + '"\n';
  text += 'Claims:\n';
  for (let i = 0; i < claims.length; i++) {
    text += i.toString() + '. ' + claims[i] + '\n';
  }

  const commonResp: FetchCommonResponse = await fetchCommon(
    text,
    'figure',
    'gpt',
    false
  );

  if (commonResp) {
    if (!commonResp.error) {
      resp.data = commonResp.data;
    } else {
      resp.error = commonResp.error;
    }
  } else {
    resp.error = 'fetch figure error';
  }

  resp.httpCode = commonResp.httpCode;

  return resp;
};

/**
 * Sends a generic request to a common API endpoint with specified GPT instructions, model and source text.
 *
 * This function constructs a request body with the provided data and sends it to the '/api/common' endpoint.
 * It then processes the response and returns a structured object containing the API's response or any errors encountered.
 *
 * @param data - The main data payload to be sent to the API.
 * @param instructionType - A string specifying the type of instruction for the API to process.
 * @param modelType - A string indicating the model type to be used for processing the data.
 * @param test - A boolean flag to indicate whether this is a test request.
 * @returns A Promise that resolves to a FetchCommonResponse object containing:
 *   - data: The processed data returned by the API (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchCommon = async (
  data: string,
  instructionType: string,
  modelType: string,
  test: boolean
) => {
  const body = JSON.stringify({
    data: data,
    instructionType: instructionType,
    modelType: modelType,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/common', body);

  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchAbstracts = async (
  data: string[],
  test: boolean
) => {
  const body = JSON.stringify({
    data: data,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/abstracts', body);

  let responseObject: FetchAbstractResponse = { ...defaultFetchAbstractResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Fetches the list of consistency items from the server (originally from Strapi).
 *
 * This function sends a GET request to retrieve consistency items based on the current
 * API language setting. It handles the server response, including any potential errors,
 * and returns the fetched data.
 *
 * @returns {Promise<FetchGetConsistencyListResponse>} A promise that resolves to an object containing:
 *   - The list of consistency items if the request was successful
 *   - Any error information if the request failed
 *   - The HTTP status code of the response (implicitly, as part of the response object)
 */
const fetchGetConsistencyList = async () => {
  let resp: FetchGetConsistencyListResponse =
    defaultFetchGetConsistencyListResponse;

  const response = await authorizedFetch(
    'GET',
    '/api/consitems/' + tempStorage.apiLanguage,
    ''
  );

  if (response.ok) {
    const responseObject: FetchGetConsistencyListResponse =
      await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  return resp;
};

/**
 * Performs a consistency check on the provided data using specified parameters.
 *
 * This function sends a POST request to the '/api/consistency' endpoint with the given data
 * and parameters. It then processes the response and returns a structured object containing
 * the consistency check results or any errors encountered.
 *
 * @param data - The main data payload to be checked for consistency.
 * @param testKey - A string identifying the specific consistency test to be applied.
 * @param modelType - A string indicating the model type to be used for the consistency check.
 * @param test - A boolean flag to indicate whether this is a test request.
 * @returns A Promise that resolves to a FetchCommonResponse object containing:
 *   - data: The consistency check results (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchConsistency = async (
  data: string,
  testKey: string,
  modelType: string,
  test: boolean
) => {
  const body = JSON.stringify({
    data: data,
    testKey: testKey,
    modelType: modelType,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/consistency', body);

  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Performs parallel consistency checks on multiple claims.
 *
 * This function sends a POST request to the '/api/parcons' endpoint with the provided claims
 * and test parameters. It processes the response and returns the results of the parallel
 * consistency checks.
 *
 * @param claims - An array of strings representing the claims to be checked for consistency.
 * @param testKey - A string identifying the specific consistency test to be applied.
 * @param modelType - A string indicating the model type to be used for the consistency checks.
 * @param test - A boolean flag to indicate whether this is a test request.
 * @returns A Promise that resolves to a FetchParallelConsistencyResponse object containing:
 *   - items: An array of consistency check results for each claim (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the response.
 */
const fetchParallelClaimsConsistency = async (
  claims: string[],
  testKey: string,
  modelType: string,
  test: boolean
) => {
  const body = JSON.stringify({
    claims: claims,
    testKey: testKey,
    modelType: modelType,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/parcons', body);

  let responseObject: FetchParallelConsistencyResponse = {
    ...defaultFetchParallelConsistencyResponse,
  };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.items);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Fetches root forms of words from the given lines of text.
 *
 * This function sends a POST request to the '/api/roots' endpoint with the provided lines
 * and the current API language. For English, it prepends 'a ' to each line. It processes
 * the response and returns an object containing the root forms or any errors encountered.
 *
 * @param lines - An array of strings, each representing a line of text to be processed for root forms.
 * @returns A Promise that resolves to a FetchRootsResponse object containing:
 *   - roots: An array of root forms for the input lines (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchRoots = async (lines: string[]) => {
  if (tempStorage.apiLanguage === 'en') {
    for (let i = 0; i < lines.length; i++) {
      lines[i] = 'a ' + lines[i];
    }
  }

  const body = JSON.stringify({
    lines: lines,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/roots', body);

  let responseObject: FetchRootsResponse = { ...defaultFetchRootsResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log(responseObject.roots);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Fetches a claim description based on the provided claim text.
 *
 * This function sends a POST request to the '/api/claim' endpoint with the provided claim
 * and additional parameters. It then processes the response and returns a structured object
 * containing the detailed claim analysis or any errors encountered.
 *
 * @param inputClaim - The text of the claim to be analyzed.
 * @param claimId - A unique identifier for the claim.
 * @param useGpt - A boolean indicating whether to use GPT for analysis.
 * @param test - A boolean flag to indicate whether this is a test request.
 * @returns A Promise that resolves to a FetchCommonResponse object containing:
 *   - data: The detailed claim analysis (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchDetailedClaim = async (
  inputClaim: string,
  claimId: number,
  useGpt: boolean,
  test: boolean
) => {
  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  const body = JSON.stringify({
    data: inputClaim,
    claimId: claimId,
    useGpt: useGpt,
    language: tempStorage.apiLanguage,
    test: test,
  });

  const response = await authorizedFetch('POST', '/api/claim', body);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Authenticates a user by sending login credentials to the server and get a login token, refresh token and backend address.
 *
 * This function sends a POST request to the login endpoint with the provided
 * login and password. It then processes the server response, updates the user's
 * authentication tokens if successful, and returns the login response.
 *
 * @param login - The user's login identifier (e.g., username or email).
 * @param password - The user's password for authentication.
 * @returns A Promise that resolves to a LoginResponse object containing:
 *   - The authentication result (success or failure).
 *   - Any error messages if the login failed.
 *   - The HTTP status code of the response.
 *   - Additional user authentication data if login was successful.
 */
const fetchLogin = async (login: string, password: string) => {
  const body = JSON.stringify({
    login: login,
    password: password,
  });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  };

  console.log('input body:' + body);

  const response = await fetch(
    process.env.PATENT_SERVER_BASE_ADDRESS + '/api/login',
    options
  );
  console.log(
    'fetchLogin finished via host: ' + process.env.PATENT_SERVER_BASE_ADDRESS
  );

  let responseObject: LoginResponse = { ...defaultLoginResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response login json done');

    if (!responseObject || responseObject.error) {
      console.error('response login object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.statusMessage);
      updateCurrentUserAllTokens(responseObject);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Refreshes the user's authentication token, gets refresh token and backend address.
 *
 * This function attempts to obtain a new access token using the current user's refresh token.
 * It sends a request to the server's refresh endpoint and processes the response.
 * If successful, it updates the user's authentication data with the new access token.
 *
 * @returns {Promise<LoginResponse>} A promise that resolves to a LoginResponse object containing:
 *   - The new access token and related authentication data if the refresh was successful.
 *   - Error information if the refresh failed.
 *   - The HTTP status code of the server response.
 */
const fetchRefreshToken = async () => {
  let responseObject: LoginResponse = { ...defaultLoginResponse };
  const currentUser = getUserLocalStorage();

  if (!isRefreshTokenValid || !currentUser) {
    return responseObject;
  }

  const body = JSON.stringify({
    refreshToken: currentUser.refreshToken,
  });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  };

  console.log('input body:' + body);

  const response = await fetch(
    process.env.PATENT_SERVER_BASE_ADDRESS + '/api/refresh',
    options
  );
  console.log(
    'fetchRefreshToken finished via host: ' +
      process.env.PATENT_SERVER_BASE_ADDRESS
  );

  if (response.ok) {
    responseObject = await response.json();
    console.log('response login json done');

    if (!responseObject || responseObject.error) {
      console.error('response login object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.statusMessage);
      updateCurrentUserAccess(
        responseObject.accessToken,
        responseObject.expiresIn,
        responseObject.backend
      );
    }
  } else {
    responseObject.error = response.statusText;
    console.error('fetchRefreshToken response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Sets a new authentication token with a specified expiration time.
 *
 * This function sends a POST request to the '/api/settoken' endpoint to update
 * the user's authentication token. It handles the server response and returns
 * a structured object containing the operation result or any errors encountered.
 *
 * @param expiresIn - The number of seconds until the new token expires.
 * @returns A Promise that resolves to a FetchCommonResponse object containing:
 *   - data: The server's response data (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - httpCode: The HTTP status code of the API response.
 */
const fetchSetToken = async (expiresIn: number) => {
  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  const body = JSON.stringify({
    expiresIn: expiresIn,
  });

  const response = await authorizedFetch('POST', '/api/settoken', body);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Fetches user information from the server.
 *
 * This function retrieves the current user's information from local storage,
 * sends an authorized GET request to the '/api/userinfo' endpoint,
 * and processes the server's response. If successful, it updates the user's
 * information in local storage.
 *
 * @returns {Promise<UserInfoResponse>} A promise that resolves to a UserInfoResponse object containing:
 *   - User information if the fetch was successful
 *   - Error information if the fetch failed
 *   - The HTTP status code of the response
 */
const fetchUserInfo = async () => {
  let responseObject: UserInfoResponse = { ...defaultUserInfoResponse };
  const currentUser = getUserLocalStorage();
  if (!currentUser) {
    return responseObject;
  }

  const response = await authorizedFetch('GET', '/api/userinfo', '');

  console.log('fetchUserInfo finished via host: ' + +currentUser.backend);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response login json done');

    if (!responseObject || responseObject.error) {
      console.error('response login object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.statusMessage);
      updateCurrentUserInfo(responseObject);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

/**
 * Generates a report based on the provided consistency tests errors.
 *
 * This function sends a POST request to the '/api/report' endpoint with the test data,
 * title, and date/time information. It then processes the server response and returns
 * the result of the report generation.
 *
 * @param tests - An array of consistencyType objects representing the tests to be included in the report.
 * @param title - The title of the report.
 * @param dateTime - A string representing the date and time of the report generation.
 * @returns A Promise that resolves to a FetchGenerateReportResponse object containing:
 *   - reportId: The ID of the generated report (if successful).
 *   - error: A string describing any error that occurred during the operation.
 *   - Other properties as defined in the FetchGenerateReportResponse type.
 */
const fetchGenerateReport = async (
  states: {[key: string]: number;},
  tests: consistencyType[],
  title: string,
  dateTime: string
) => {
  let responseObject: FetchGenerateReportResponse =
    defaultFetchGenerateReportResponse;

  const body = JSON.stringify({
    tests: tests,
    states: states,
    title: title,
    dateTime: dateTime,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/report', body);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.reportId);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  return responseObject;
};

/**
 * Performs an authorized fetch request to the specified API endpoint.
 *
 * This function handles authentication by including the user's access token in the request headers.
 * If the initial request fails due to an expired token (401 status), it attempts to refresh the token
 * and retry the request with the new token.
 *
 * @param method - The HTTP method for the request (e.g., 'GET', 'POST', 'PUT', 'DELETE').
 * @param path - The API endpoint path to be appended to the base URL.
 * @param body - The request body as a string. If empty, it will be omitted from the request.
 * @returns A Promise that resolves to the Response object from the fetch request, or null if the user is not authenticated.
 */
const authorizedFetch = async (method: string, path: string, body: string) => {
  let response: Response = null;

  const currentUser = getUserLocalStorage();
  if (!currentUser) {
    return response;
  }

  const options: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUser.accessToken}`,
    },
  };

  console.log('input body:' + body);

  if (body) {
    options.body = body;
  }

  response = await fetch(currentUser.backend + path, options);
  console.log('fetch finished for: ' + currentUser.backend + path);

  if (response.ok) {
    console.log('response ok');
  } else {
    if (response.status === 401) {
      const refreshResult = await fetchRefreshToken();
      if (refreshResult.accessToken) {
        await fetchSetToken(refreshResult.expiresIn);
        options.headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshResult.accessToken}`,
        };

        response = await fetch(currentUser.backend + path, options);
        console.log('fetch2 finished for: ' + currentUser.backend + path);
      }
    }
  }

  return response;
};

/**
 * Updates the current user's information in local storage based on the provided response object.
 *
 * This function retrieves the current user from local storage, updates their information
 * with the data from the response object, and then saves the updated user information
 * back to local storage. It also updates the backend information in temporary storage.
 *
 * @param responseObject - An object containing the updated user information
 * @param responseObject.email - The user's email address
 * @param responseObject.emailVerified - Boolean indicating if the user's email is verified
 * @param responseObject.givenName - The user's given name
 * @param responseObject.familyName - The user's family name
 * @param responseObject.preferredUsername - The user's preferred username
 * @param responseObject.sub - The user's subject identifier
 * @param responseObject.backend - The backend server URL
 *
 * @returns {boolean} True if the user information was successfully updated, false if no current user was found
 */
const updateCurrentUserInfo = (responseObject: UserInfoResponse) => {
  const currentUser = getUserLocalStorage();
  if (!currentUser) {
    return false;
  }
  currentUser.email = responseObject.email;
  currentUser.emailVerified = responseObject.emailVerified;
  currentUser.givenName = responseObject.givenName;
  currentUser.familyName = responseObject.familyName;
  currentUser.preferredUsername = responseObject.preferredUsername;
  currentUser.sub = responseObject.sub;
  currentUser.backend = responseObject.backend;
  tempStorage.backend = currentUser.backend;
  updateUserLocalStorage(currentUser);
  console.log('User info updated with backend: ' + tempStorage.backend);
  return true;
};

/**
 * Updates all authentication tokens for the current user based on the login response.
 *
 * This function updates the access token, refresh token, their expiration times,
 * and the backend server URL for the current user. It calculates the expiration
 * timestamps based on the current time and the provided expiration durations.
 *
 * @param responseObject - The login response object containing new authentication data.
 * @param responseObject.accessToken - The new access token for the user.
 * @param responseObject.expiresIn - The number of seconds until the access token expires.
 * @param responseObject.refreshToken - The new refresh token for the user.
 * @param responseObject.refreshExpiresIn - The number of seconds until the refresh token expires.
 * @param responseObject.backend - The URL of the backend server.
 *
 * @returns {void} This function doesn't return a value, but updates user data in local storage.
 */
const updateCurrentUserAllTokens = (responseObject: LoginResponse) => {
  const currentUser = getUserLocalStorage();
  currentUser.accessToken = responseObject.accessToken;
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  currentUser.expiresAt = unixTimeStampInSeconds + responseObject.expiresIn;
  currentUser.refreshToken = responseObject.refreshToken;
  currentUser.refreshExpiresAt =
    unixTimeStampInSeconds + responseObject.refreshExpiresIn;

  currentUser.backend = responseObject.backend;
  tempStorage.backend = currentUser.backend;
  updateUserLocalStorage(currentUser);
  console.log('User all tokens updated with backend: ' + tempStorage.backend);
};

/**
 * Checks if the current user's access token is valid and refreshes it if necessary.
 *
 * This function performs the following steps:
 * 1. Retrieves the current user from local storage.
 * 2. Checks if the access token exists and is not expired.
 * 3. If the token is invalid or expired, it attempts to refresh the token.
 * 4. If there's no current user, it initializes with default user data.
 * 5. If the token is successfully refreshed, it updates the token expiration.
 *
 * @returns {Promise<boolean>} A promise that resolves to:
 *   - true if the access token is valid or was successfully refreshed
 *   - false if the token couldn't be validated or refreshed
 */
const isAccessTokenValid = async () => {
  const currentUser = getUserLocalStorage();
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  let isValid = false;
  if (
    currentUser &&
    currentUser.accessToken &&
    currentUser.expiresAt > unixTimeStampInSeconds
  ) {
    isValid = true;
  } else {
    if (!currentUser) {
      updateUserLocalStorage(defaultUser);
    }
    const refreshResult = await fetchRefreshToken();
    if (refreshResult.accessToken) {
      isValid = true;
      await fetchSetToken(refreshResult.expiresIn);
    }
  }

  return isValid;
};

/**
 * Checks if the current user's refresh token is valid.
 *
 * This function retrieves the current user from local storage and compares
 * the refresh token's expiration time with the current timestamp. A refresh
 * token is considered valid if it exists and has not yet expired.
 *
 * @returns {boolean} True if the refresh token is valid and not expired, false otherwise.
 */
const isRefreshTokenValid = () => {
  const currentUser = getUserLocalStorage();
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  let isValid = false;
  if (
    currentUser &&
    currentUser.refreshToken &&
    currentUser.refreshExpiresAt > unixTimeStampInSeconds
  ) {
    isValid = true;
  }

  return isValid;
};

/**
 * Updates the current user's access information in local storage.
 *
 * This function updates the access token, its expiration time, and the backend server URL
 * for the current user. If no current user exists in local storage, it initializes with default user data.
 *
 * @param accessToken - The new access token for the user.
 * @param expiresIn - The number of seconds until the access token expires.
 * @param backend - The URL of the backend server.
 *
 * @returns {void} This function doesn't return a value, but updates user data in local storage and temporary storage.
 */
const updateCurrentUserAccess = (
  accessToken: string,
  expiresIn: number,
  backend: string
) => {
  let currentUser = getUserLocalStorage();
  if (!currentUser) {
    currentUser = { ...defaultUser };
  }

  currentUser.accessToken = accessToken;
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  currentUser.expiresAt = unixTimeStampInSeconds + expiresIn;
  currentUser.backend = backend;
  tempStorage.backend = currentUser.backend;

  updateUserLocalStorage(currentUser);
  console.log('User access updated with backend: ' + tempStorage.backend);
};

export {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchGetTemplatesList,
  fetchTemplateDocumentContent,
  fetchDistances,
  fetchAllDistances,
  fetchTermsDescriptions,
  fetchUpdateDescription,
  fetchAbstracts,
  fetchCommon,
  fetchGetConsistencyList,
  fetchConsistency,
  fetchParallelClaimsConsistency,
  fetchRoots,
  fetchDetailedClaim,
  fetchTermLookup,
  fetchGetAllTerms,
  fetchGetTermsFiltered,
  fetchGetTerm,
  fetchReferences,
  fetchSearchStemmed,
  fetchFigure,
  fetchTestSingleClaim,
  fetchParallelTestClaims,
  fetchLogin,
  fetchRefreshToken,
  fetchUserInfo,
  fetchSetToken,
  fetchGenerateReport,
  isAccessTokenValid,
};
