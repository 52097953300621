import { fetchSearchStemmed } from './apiHelpers';
import {
  getBookmarkErrorIdFromParId,
  getErrorIdFromText,
  getMultiBlocksParagraphs,
  insertBookmarkErrorIntoParId,
  insertErrorMarkerIntoParId,
} from './documentProxy';
import {
  getLocalStorage,
  getTestErrorById,
  getTestErrors,
  setTestErrors,
  testErrorType,
} from './localStorageHelper';
import { PromiseStatus, StatusCodes, StatusManager } from './models';
import {
  getFirstWord,
  getContext,
  getConsistencyByTestKey,
} from './consistency';
import { UIStrings } from 'app/UIStrings';
import {
  analyzeReferences,
  setTotalProgressInBulk,
  updateRoots,
} from './addInMethods';


/**
 * Asynchronously checks the consistency of references in the document.
 * @param {StatusManager} statusManager - The status manager object.
 * @param {PromiseStatus} promiseStatus - The promise status object.
 * @param {Function} resolve - The function to call when the operation is completed.
 * @returns None
 */
const checkReferencesConsistency = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  const testName = 'references';
  const foundErrors: testErrorType[] = [];

  const consItem = getConsistencyByTestKey(testName);

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(
    UIStrings.statusbar.checking_references_progress
  );

  await updateRoots();

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  let allParagraphs = await getMultiBlocksParagraphs(['figures']);
  const figuresParagraphsCount = allParagraphs.length;

  setTotalProgressInBulk(promiseStatus, 30);
  statusManager.setProgress(promiseStatus.progress);

  let claimsParagraphs = await getMultiBlocksParagraphs(['claims']);
  const claimsParagraphsCount = claimsParagraphs.length;
  allParagraphs.push(...claimsParagraphs);

  const paragraphTexts: string[] = [];
  setTotalProgressInBulk(promiseStatus, 40);
  statusManager.setProgress(promiseStatus.progress);

  if (!promiseStatus.aborted) {
    const ares = analyzeReferences();

    // Check inline references.
    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      // Store original text
      paragraphTexts.push(paragraph.text);
    }

    const resp = await fetchSearchStemmed(
      paragraphTexts,
      ares.keywords,
      ares.roots
    );

    if (resp.error) {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(
        UIStrings.statusbar.checking_references_failed
      );
    } else {
      setTotalProgressInBulk(promiseStatus, 50);
      statusManager.setProgress(promiseStatus.progress);
      
      for (let p = 0; p < resp.paragraphs.length; p++) {
        let delta = 0;
        const respPar = resp.paragraphs[p];
        const paragraph = allParagraphs[respPar.parIndex];
        let parText = paragraphTexts[respPar.parIndex];

        for (let s = 0; s < respPar.searchResults.length; s++) {
          const startIndex = respPar.searchResults[s].startIndex;
          const endIndex = respPar.searchResults[s].endIndex;

          const refId = ares.references[respPar.searchResults[s].keyword];
          let expectedRefTag = '(' + refId + ')';

          let isClaimsZone = true;
          if (respPar.parIndex < figuresParagraphsCount) {
            expectedRefTag = refId;
            isClaimsZone = false;
          }

          console.log('expectedRefTag:"' + expectedRefTag + '"');

          console.log('startIndex:' + startIndex);
          console.log('endIndex:' + endIndex);

          let allowComma = false;
          if (expectedRefTag.includes(',')) {
            allowComma = true;
          }

          const maybeRefTag = getFirstWord(
            parText,
            endIndex + 1, // Starting from space after referred word.
            allowComma
          );

          console.log('maybeRefTag:"' + maybeRefTag + '"');

          const maybeStartOfRefTag = endIndex + 2; // shift right to space then to first char of Ref Tag
          const maybeEndOfRefTag = maybeStartOfRefTag + maybeRefTag.length - 1;

          const errContext = getContext(
            parText,
            maybeStartOfRefTag,
            maybeEndOfRefTag
          );
          const substring = parText.substring(
            maybeStartOfRefTag,
            maybeEndOfRefTag + 1 //'substring' doesn't include the endIndex char.
          );

          const contextIdx = parText.indexOf(errContext);

          const startOfRefTagInContext = maybeStartOfRefTag - contextIdx;
          const endOfRefTagInContext = maybeEndOfRefTag - contextIdx;

          console.log('maybeStartOfRefTag:' + maybeStartOfRefTag);
          console.log('maybeEndOfRefTag:' + maybeEndOfRefTag);

          console.log('startOfRefTagInContext:' + startOfRefTagInContext);
          console.log('endOfRefTagInContext:' + endOfRefTagInContext);

          if (expectedRefTag.localeCompare(maybeRefTag) !== 0) {
            console.log('error substring:"' + substring + '"');
            console.log('error context:"' + errContext + '"');

            let refExists = true;

            if (
              isClaimsZone &&
              !maybeRefTag.startsWith('(') &&
              !maybeRefTag.endsWith(')')
            ) {
              refExists = false;
            }

            function hasNumber(myString) {
              return /\d/.test(myString);
            }

            if (!hasNumber(maybeRefTag)) {
              refExists = false;
            }

            const newContext =
              errContext.substring(0, endOfRefTagInContext + 1) +
              expectedRefTag +
              errContext.substring(endOfRefTagInContext + 1);

            // Check if error already present.
            let errorId = await getBookmarkErrorIdFromParId(
              paragraph.uniqueLocalId,
              startIndex,
              endIndex
            );
            if (errorId) {
              console.log('error found:' + errorId);
            } else {
              errorId = await insertBookmarkErrorIntoParId(
                paragraph.uniqueLocalId,
                !refExists,
                startIndex + delta,
                endIndex + delta
              );
              console.log('ErrorId generated:' + errorId);
            }
            console.log('Final ErrorId:' + errorId);
            const oldError = getTestErrorById('references', errorId);
            if (oldError) {
              console.log('Old ErrorId found in local storage:' + errorId);
              const testError: testErrorType = {
                id: oldError.id,
                skip: oldError.skip,
                skipReason: oldError.skipReason,
                context: oldError.context,
                contextIdx: oldError.contextIdx,
                substring: oldError.substring,
                errStart: oldError.errStart,
                errEnd: oldError.errEnd,
                corrStart: oldError.corrStart,
                corrEnd: oldError.corrEnd,
              };
              foundErrors.push(testError);
            } else {
              const testError: testErrorType = {
                skip: false,
                skipReason: '',
                id: errorId,
                context: newContext,
                contextIdx: contextIdx + delta,
                substring: substring,
                errStart: startOfRefTagInContext,
                errEnd: endOfRefTagInContext,
                corrStart: endOfRefTagInContext + 1,
                corrEnd: endOfRefTagInContext + expectedRefTag.length,
              };
              if (!refExists) {
                insertDummyErrorRef(testError);
                delta += 4;
              }
              foundErrors.push(testError);
            }
          }
        }

        setTotalProgressInBulk(
          promiseStatus,
          60 + p * (40 / resp.paragraphs.length)
        );
        statusManager.setProgress(promiseStatus.progress);
      }
    }

    setTestErrors('references', consItem.testDisplayName, foundErrors);

    setTotalProgressInBulk(promiseStatus, 100);
    statusManager.setProgress(promiseStatus.progress);
    if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
      statusManager.setStatusCode(StatusCodes.Success);
    }
    statusManager.setStatusMessage(
      UIStrings.statusbar.checking_references_completed
    );

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

/**
 * Inserts a dummy error reference into the test error context.
 * It is used in cases where a reference doesn't exist in the claims zone.
 * This function modifies the provided testError object by inserting a dummy
 * reference '(!)' and adjusting the error and correction indices accordingly.
 *
 * @param testError - The test error object to be modified.
 * @param testError.context - The original context string containing the error.
 * @param testError.errStart - The starting index of the error in the original context.
 * @param testError.errEnd - The ending index of the error in the original context.
 * @param testError.corrStart - The starting index of the correction in the original context.
 * @param testError.corrEnd - The ending index of the correction in the original context.
 * @param testError.substring - The substring representing the error.
 *
 * @returns void This function doesn't return a value, it modifies the input testError object.
 */
const insertDummyErrorRef = (testError: testErrorType) => {
  const dummy = '(!)';
  let newContext = testError.context.substring(0, testError.errStart);
  newContext += dummy;
  newContext += testError.context.substring(
    testError.corrStart,
    testError.corrEnd + 1
  );
  newContext += testError.context.substring(
    testError.errStart,
    testError.errEnd + 1
  );
  newContext += ' ';
  newContext += testError.context.substring(testError.corrEnd + 1);

  const corrLen = testError.corrEnd - testError.corrStart + 1;
  testError.errEnd = testError.errStart + dummy.length - 1;
  testError.corrStart = testError.errEnd + 1;
  testError.corrEnd = testError.corrStart + corrLen - 1;
  testError.substring = dummy;
  testError.context = newContext;
};

export { checkReferencesConsistency };
