import { useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { abstractType } from 'engine/localStorageHelper';
import * as classes from 'components/AbstractItem/AbstractItem.scss';
import InputError from 'components/InputError/InputError';
import { UIStrings } from 'app/UIStrings';

type AbstractItemProps = {
  abstract: abstractType;
  isNew?: boolean;
  cancel?: () => void;
  createCustomAbstract?: (title: string) => void;
  deleteAbstract?: (abstract: abstractType) => void;
  updateAbstractInLocalStorage?: (
    abstract: abstractType,
    newTitle: string
  ) => void;
  checkDuplicateAbstract?: (refNumber: string) => boolean;
  hasError?: boolean; // New prop for error state
};

const AbstractItem = ({
  abstract,
  isNew,
  cancel,
  createCustomAbstract,
  deleteAbstract,
  updateAbstractInLocalStorage,
  hasError = false, // Default to false
}: AbstractItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: abstract.id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const prevPatentNumberRef = useRef<string>(abstract.patentNumber);
  const [patentNumber, setPatentNumber] = useState<string>(abstract.patentNumber);

  /**
   * Updates the patent number.
   * @param newTitle - The new patent number to be set for the abstractItem.
   * @returns void
   */
  const handleUpdatePatentNumber = (newPatentNumber: string) => {
    if (prevPatentNumberRef.current === newPatentNumber) {
      return;
    }

    updateAbstractInLocalStorage(abstract, newPatentNumber);

    prevPatentNumberRef.current = newPatentNumber.trim();
    setPatentNumber(newPatentNumber.trim());
  };

  /**
   * Creates a new empty abstractItem.
   * @returns void
   */
  const handleCreateAbstract = () => {
    if (!patentNumber.trim()) return;

    cancel();
    createCustomAbstract(patentNumber.trim());
    setPatentNumber(patentNumber.trim());
  };

  return (
    <div
      className={classes.container}
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      {isNew ? (
        <div className={`${classes.titleWrapper} ${classes.newTitle}`}>
          <input
            type="text"
            id="title"
            className={classes.titleInput}
            autoFocus
            value={patentNumber}
            onChange={(e) => setPatentNumber(e.target.value)}
          />

          <div className={classes.options}>
            <div
              className={`${classes.icon} ${classes.dismissIcon}`}
              onClick={cancel}
            ></div>
            <div
              className={`${classes.icon} ${classes.applyIcon}`}
              onClick={handleCreateAbstract}
            ></div>
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.titleWrapper}>
            <div
              className={`${classes.icon} ${classes.dragIcon}`}
              {...listeners}
            ></div>

            <input
              type="text"
              id="title"
              className={classes.titleInput}
              value={patentNumber}
              onBlur={(e) => handleUpdatePatentNumber(e.target.value)}
              onChange={(e) => setPatentNumber(e.target.value)}
            />

            <div
              className={`${classes.icon} ${classes.trashIcon}`}
              onClick={() =>
                deleteAbstract({
                  id: abstract.id,
                  patentNumber: patentNumber,
                })
              }
            ></div>
          </div>
          {hasError && (
            <InputError errorMessage={UIStrings.abstracts.abstracts_error} />
          )}
        </div>
      )}
    </div>
  );
};

export default AbstractItem;
