import { TempStorage, tempStorage } from 'app/index';
import {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchDistances,
  fetchGetConsistencyList,
  fetchLogin,
} from './apiHelpers';
import {
  checkAlgoAllSubjectivesConsistency,
  checkAlgoClaimsAmountConsistency,
  checkAlgoClaimsDescriptionsConsistency,
  checkAlgoClaimsNumberingConsistency,
  checkGptClaimsConsistencyParallel,
} from './checkClaims';
import { checkReferencesConsistency } from './checkReferences';
import { updateErrorWithText, scrollErrorIntoView } from './documentProxy';
import { getTestErrorById, testErrorType } from './localStorageHelper';
import {
  ConsistencyInstructionItem,
  PromiseStatus,
  StatusCodes,
  StatusManager,
} from './models';
import { UIStrings } from 'app/UIStrings';

const CONTEXT_SPREAD: number = 25;

/**
 * Checks consistency based on the provided test key.
 * References consistency, Algo claims consistency, and GPT claims consistency.
 * This function determines the type of consistency check to perform and delegates to the appropriate function.
 *
 * @param statusManager - Manages the status of the consistency check process.
 * @param testKey - A string that identifies the type of consistency check to perform.
 * @param promiseStatus - Tracks the progress and status of the asynchronous operation.
 * @param resolve - A function to resolve the promise when the consistency check is complete.
 * @returns A Promise that resolves when the consistency check is complete.
 */
const checkAnyConsistency = async (
  statusManager: StatusManager,
  testKey: string,
  promiseStatus: PromiseStatus,
  resolve
) => {
  if (testKey.startsWith('references')) {
    await checkReferencesConsistency(statusManager, promiseStatus, resolve);
  } else {
    const consItem = getConsistencyByTestKey(testKey);
    if (consItem.modelType === 'algo') {
      // Algo types.
      await checkAlgoConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
    } else {
      //await checkGptClaimsConsistency(
      await checkGptClaimsConsistencyParallel(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
    }
  }
};

/**
 * Performs algorithm-based consistency checks on patent claims based on the specified test key.
 * This function delegates to specific consistency check functions depending on the test key.
 *
 * @param statusManager - Manages the status of the consistency check process.
 * @param consItem - Contains information about the consistency check to be performed.
 * @param promiseStatus - Tracks the progress and status of the asynchronous operation.
 * @param resolve - A function to resolve the promise when the consistency check is complete.
 * @returns A Promise that resolves when the consistency check is complete.
 */
const checkAlgoConsistency = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  switch (consItem.testKey) {
    case 'claims-amount': {
      await checkAlgoClaimsAmountConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
    case 'claims-numbering': {
      await checkAlgoClaimsNumberingConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
    case 'claims-descriptions': {
      await checkAlgoClaimsDescriptionsConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
    case 'common-subjectives':
    case 'common-ambiguous': {
      await checkAlgoAllSubjectivesConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
  }
};

/**
 * Extracts the first word from a given text starting from a specified index.
 * The function considers punctuation and special characters to determine word boundaries.
 * 
 * @param text - The input string from which to extract the first word.
 * @param startIndex - The index in the text from which to start searching for the first word.
 * @param allowComma - A boolean flag indicating whether commas should be allowed within the word.
 * @returns The first word found in the text starting from the specified index.
 */
const getFirstWord = (
  text: string,
  startIndex: number,
  allowComma: boolean
) => {
  let word = '';
  let stopChars: string[] = ['.', ',', ';', ' ', '\r', '\t', '\n'];
  if (allowComma) {
    stopChars = ['.', ';', ' ', '\r', '\t', '\n'];
  }
  let opened = false;
  for (let i = startIndex; i < text.length; i++) {
    if (
      !stopChars.includes(text.charAt(i)) ||
      (word.length > 0 && word.endsWith(','))
    ) {
      if (text.charAt(i) === '<') {
        if (!opened) {
          opened = true;
          word += text.charAt(i);
        } else {
          break;
        }
      } else {
        word += text.charAt(i);
      }
    } else {
      if (word.length > 0) {
        break;
      }
    }
  }
  return word;
};

/**
 * Extracts a context string from a given text, centered around specified start and end indices.
 * The function trims the context to start and end at complete words or sentences.
 *
 * @param text - The full text from which to extract the context.
 * @param startIndex - The starting index of the target text within the full text.
 * @param endIndex - The ending index of the target text within the full text.
 * @returns A string containing the extracted context, trimmed to start and end at complete words or sentences.
 */
const getContext = (text: string, startIndex: number, endIndex: number) => {
  const startContextIndex = Math.max(0, startIndex - CONTEXT_SPREAD);
  const endContextIndex = Math.min(endIndex + CONTEXT_SPREAD, text.length - 1);
  let stopChars: string[] = ['.', ',', ';', ' ', '\r', '\t', '\n'];

  let context = text.substring(startContextIndex, endContextIndex + 1);

  let trimmedLeft = '';
  let result = '';

  if (startContextIndex > 0) {
    // If context is not started from the beginning of paragraph.
    let active = false;
    for (let i = 0; i < context.length; i++) {
      if (active) {
        trimmedLeft += context[i];
      }
      if (!active && stopChars.includes(context[i])) {
        if (i + 1 < context.length && !stopChars.includes(context[i + 1])) {
          active = true;
        }
      }
    }
  } else {
    trimmedLeft = context;
  }

  if (endContextIndex < text.length - 1) {
    // context finished earlier than paragraph
    let active = false;
    for (let i = trimmedLeft.length - 1; i >= 0; i--) {
      if (active) {
        result = trimmedLeft[i] + result;
      }
      if (!active && stopChars.includes(trimmedLeft[i])) {
        if (i - 1 >= 0 && !stopChars.includes(trimmedLeft[i - 1])) {
          active = true;
        }
      }
    }
  } else {
    result = trimmedLeft;
  }

  return result;
};

/**
 * Corrects a single error in the document and updates the status.
 * 
 * @param statusManager - Manages the status of the error correction process.
 * @param error - The error to be corrected, of type testErrorType.
 * @param promiseStatus - Tracks the progress and status of the asynchronous operation.
 * @param resolve - A function to resolve the promise when the error correction is complete.
 * @returns A Promise that resolves when the error correction is complete.
 */
const correctSingleError = async (
  statusManager: StatusManager,
  error: testErrorType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  promiseStatus.progress = 0;
  statusManager.setProgress(promiseStatus.progress);
  const res = await updateErrorWithText(error);

  promiseStatus.progress = 100;
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.Success);
  statusManager.setStatusMessage(UIStrings.statusbar.error_corrected);

  resolve({ completed: true });
};

/**
 * Scrolls the view to the specified error in the document.
 * 
 * @param error - The error to be viewed, of type testErrorType.
 * @param resolve - A function to resolve the promise when the error is in view.
 * @returns A Promise that resolves when the error is scrolled into view.
 */
const viewError = async (
  error: testErrorType,
  resolve
) => {
  const res = await scrollErrorIntoView(error);
  resolve({ completed: true });
};

/**
 * Refreshes the consistency list by fetching it from the server and updating the temporary storage.
 * 
 * @returns A Promise that resolves when the consistency list is refreshed.
 */
const refreshConsistencyList = async () => {
  const resp = await fetchGetConsistencyList();
  console.log(resp);
  if (resp && !resp.error) {
    tempStorage.consistencyList = resp.items;
  }
};

/**
 * Retrieves a consistency instruction item from the temporary storage based on the provided test key.
 * 
 * @param testKey - A string that uniquely identifies the consistency test.
 * @returns The ConsistencyInstructionItem matching the provided test key, or undefined if no match is found.
 */
const getConsistencyByTestKey = (testKey: string) => {
  const consItem: ConsistencyInstructionItem = tempStorage.consistencyList.find(
    (test) => test.testKey === testKey
  );
  return consItem;
};

export {
  checkReferencesConsistency,
  checkAnyConsistency,
  correctSingleError,
  viewError,
  refreshConsistencyList,
  getConsistencyByTestKey,
  getFirstWord,
  getContext,
};
