import { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { FluentProvider } from '@fluentui/react-components';

import { patentLightTheme } from 'app/theme';
import { loginAction, loginLoader, protectedLoader } from 'app/auth';
import { initDocument } from 'app/office-document';
import {
  ConsistencyInstructionItem,
  defaultLoginResponse,
  LoginResponse,
} from 'engine/models';
import {
  getLocalStorage,
  localStorageType,
  statusType,
} from 'engine/localStorageHelper';

// Routes
import App from 'views/App/App';
import Login from 'views/Login/Login';
import Template from 'views/Template/Template';
import TextGeneration from 'views/TextGeneration/TextGeneration';
import Definition from 'views/Definition/Definition';
import Reference from 'views/Reference/Reference';
import Abstracts from 'views/Abstracts/Abstracts';
import Figures from 'views/Figures/Figures';
import Consistency from 'views/Consistency/Consistency';
import Settings from 'views/Settings/Settings';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import FinalDocument from './views/FinalDocument/FinalDocument';
import PatentFormula from './views/PatentFormula/PatentFormula';
import ConsistencyClaims from './views/ConsistencyClaims/ConsistencyClaims';
import { UIStrings } from './UIStrings';

/* global Office, module, require */

export class TempStorage {
  totalTerms: string[];
  origTerms: string[];
  definitionsFirstInsert: boolean;
  loginResponse: LoginResponse;
  apiLanguage: string;
  reportId: string;
  patentTitle: string;
  consistencyList: ConsistencyInstructionItem[];
  backend: string;
}
export const tempStorage: TempStorage = {
  totalTerms: [],
  origTerms: [],
  definitionsFirstInsert: true,
  loginResponse: defaultLoginResponse,
  apiLanguage: 'en',
  reportId: '',
  patentTitle: '',
  consistencyList: [],
  backend: '',
};

export const defaultStatus: statusType = {
  generation: {
    tabStatus: 0,
    actionStatus: {
      title: false,
      text: false,
      description: false,
      summary: false,
    },
  },
  definitions: 0,
  references: 0,
  abstracts: 0,
  figures: 0,
  consistencyErrorsCount: 0,
};
export const StatusContext = createContext<statusType>(defaultStatus);

const rootElement: HTMLElement = document.getElementById('container');
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  const officeLocale = Office.context.displayLanguage;
  if (officeLocale) {
    UIStrings.setLanguage(officeLocale);

    if (officeLocale.toLowerCase().startsWith('de')) {
      tempStorage.apiLanguage = 'de';
    } else {
      tempStorage.apiLanguage = 'en';
    }
  }

  console.log('Set API language from UI: ' + tempStorage.apiLanguage);

  initDocument()
    .then(() => {
      const localStorage: localStorageType = getLocalStorage();
      const status: statusType = localStorage.status;

      const router = createBrowserRouter([
        {
          path: '/index.html',
          element: <App />,
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Login />,
              action: loginAction,
              loader: loginLoader,
            },
            {
              path: '/index.html/template',
              element: <Template />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/patent-formula',
              element: <PatentFormula />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/consistency-claims',
              element: <ConsistencyClaims />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/generation',
              element: <TextGeneration />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/definition',
              element: <Definition />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/reference',
              element: <Reference />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/figures',
              element: <Figures />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/consistency',
              element: <Consistency />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/abstracts',
              element: <Abstracts />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/settings',
              element: <Settings />,
              loader: protectedLoader,
            },
            {
              path: '/index.html/final',
              element: <FinalDocument />,
              loader: protectedLoader,
            },
          ],
        },
      ]);

      root.render(
        <FluentProvider theme={patentLightTheme}>
          <StatusContext.Provider value={status}>
            <RouterProvider router={router} />
          </StatusContext.Provider>
        </FluentProvider>
      );
    })
    .catch(() => {
      root.render(<h1>Please Reload The App</h1>);
    });
});

if ((module as any).hot) {
  (module as any).hot.accept('views/App/App', () => {
    const NextApp = require('views/App/App').default;
    root.render(NextApp);
  });
}
