import { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useResolvedPath,
} from 'react-router-dom';
import { Divider } from '@fluentui/react-components';

import { initDocumentWithTemplate } from 'app/office-document';
import { fetchGetTemplatesList } from 'engine/apiHelpers';
import { RouterContextType } from 'views/App/App';
import { defaultStatus } from 'app/index';
import * as classes from 'views/Template/Template.scss';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import { UIStrings } from 'app/UIStrings';

const Template = () => {
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Forward to reference screen if no template is chosen
  const resolvedCurrentDocument = useResolvedPath(`/index.html/reference`);
  const resolvedTemplate = useResolvedPath(`/index.html/patent-formula`);
  const { status, setStatus }: RouterContextType = useOutletContext();

  /**
   * Fetches the list of templates and updates the component state.
   * This effect runs once when the component mounts.
   *
   * @remarks
   * If the API response indicates an unauthorized access (HTTP 401),
   * it redirects the user to the home page.
   * Otherwise, it updates the templates state and sets loading to false.
   *
   * @returns {void}
   */
  useEffect(() => {
    fetchGetTemplatesList().then((response) => {
      if (response.httpCode === 401) {
        return navigate('/index.html', { replace: true });
      }

      setTemplates(response.templates);
      setIsLoading(false);
    });
  }, []);

  /**
   * Selects a template and initializes the document with it.
   *
   * @param templateName - The name of the template to be used for document initialization.
   * @param language - The language of the template.
   * @returns A promise that resolves when the document has been initialized with the selected template.
   *          The status is set to the default status upon successful initialization.
   */
  const selectTemplate = async (templateName: string, language: string) => {
    await initDocumentWithTemplate(templateName, language).then(() => {
      setStatus(defaultStatus);
    });
  };

  return (
    <div>
      {isLoading && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.templates.template_title}
          description={UIStrings.templates.template_description}
          color="white"
        />

        {/* <div className={classes.searchContainer}>
        <div
          className={classes.searchInputWrapper}
          onClick={handleInputClick}
          onKeyDown={handleKeyClick}
        >
          <div className={`${classes.icon} ${classes.searchIcon}`}></div>
          <input
            className={classes.searchInput}
            placeholder="Suchen…"
            ref={searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div> */}

        <Link
          to={resolvedCurrentDocument.pathname}
          className={classes.link}
          style={{ width: '298px' }}
        >
          <Button
            title={UIStrings.templates.continue}
            color="white"
            size="big"
          />
        </Link>

        <Divider className={classes.divider}>
          {UIStrings.templates.choose_template}
        </Divider>

        {templates.length > 0 && (
          <div className={classes.templates}>
            {templates.map((template) => (
              <Link
                key={template.name}
                to={resolvedTemplate.pathname}
                onClick={() => selectTemplate(template.name, template.language)}
                className={classes.link}
              >
                <div className={classes.templateItem}>
                  <div className={classes.title}>
                    <div>{template.title}</div>
                    <div
                      className={`${classes.icon} ${classes.arrowRightIcon}`}
                    ></div>
                  </div>
                  <div className={classes.description}>
                    <span>{template.description}</span>
                    <span className={classes.language}>
                      {template.language.toUpperCase()}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Template;
