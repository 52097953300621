type CustomTerm = {
  term: string;
  description: string;
  enabled: boolean;
};

type definitionType = {
  title: string;
  parentText: string;
  description: string;
  checked: boolean;
  changed: boolean;
  custom: boolean;
};

type referenceType = {
  id: string;
  title: string;
  rootWord: string;
  leftPhrase: string;
  searchWholePhrase: boolean;
  referenceNumber: string;
};

type abstractType = {
  id: string;
  patentNumber: string;
  hasError?: boolean; // New optional attribute
};

type suggestionType = {
  title: string;
  enabled: boolean;
};

type figureType = {
  id: string;
  title: string;
  description: string;
  claimIds: number[];
  complete: boolean;
};

type settingsType = {
  isInsertDefinition: boolean;
  maxPatentClaims: number;
};

type generationStatusType = {
  tabStatus: number;
  actionStatus: {
    title: boolean;
    text: boolean;
    description: boolean;
    summary: boolean;
  };
};

type statusType = {
  generation: generationStatusType;
  definitions: number;
  references: number;
  abstracts: number;
  figures: number;
  consistencyErrorsCount: number;
};

type testErrorType = {
  id: string;
  context: string;
  contextIdx: number;
  substring: string;
  skip: boolean;
  skipReason: string;
  errStart: number;
  errEnd: number;
  corrStart: number;
  corrEnd: number;
};

type consistencyType = {
  testName: string;
  testTitle: string;
  testErrors: testErrorType[];
};

type localStorageType = {
  documentTitle: string;
  claims: string[];
  claimDescriptions: string[];
  customTerms: CustomTerm[];
  definitions: definitionType[];
  references: referenceType[];
  abstracts: abstractType[];
  suggestions: suggestionType[];
  figures: figureType[];
  consistency: consistencyType[];
  settings: settingsType;
  status: statusType;
};

type userType = {
  accessToken: string;
  backend: string;
  expiresAt: number; // unixTimeStampInSeconds
  refreshToken: string;
  refreshExpiresAt: number; // unixTimeStampInSeconds
  sub: string;
  emailVerified: boolean;
  name: string;
  preferredUsername: string;
  givenName: string;
  familyName: string;
  email: string;
};

const defaultUser: userType = {
  accessToken: '',
  backend: '',
  expiresAt: 0,
  refreshToken: '',
  refreshExpiresAt: 0,
  sub: '',
  emailVerified: false,
  name: '',
  preferredUsername: '',
  givenName: '',
  familyName: '',
  email: '',
};

const defaultLocalStorage: localStorageType = {
  documentTitle: 'Patent Software',
  claims: [],
  claimDescriptions: [],
  customTerms: [],
  definitions: [],
  references: [],
  abstracts: [],
  suggestions: [],
  figures: [],
  consistency: [],
  settings: {
    isInsertDefinition: true,
    maxPatentClaims: 10,
  },
  status: {
    generation: {
      tabStatus: 0,
      actionStatus: {
        title: false,
        text: false,
        description: false,
        summary: false,
      },
    },
    definitions: 0,
    references: 0,
    abstracts: 0,
    figures: 0,
    consistencyErrorsCount: 0,
  },
};

let documentId: string;

/**
 * Sets the document ID for local storage operations.
 * @param id - The unique identifier for the document.
 */
const setDocumentId = (id: string) => {
  documentId = id;
};

/**
 * Initializes the local storage with default values for the current document.
 * Uses the document ID to create a unique key in local storage.
 * Catches and logs any errors that occur during the process.
 */
const initLocalStorage = () => {
  try {
    window.localStorage.setItem(
      `${documentId}_patent`,
      JSON.stringify(defaultLocalStorage)
    );
  } catch (error) {
    console.error(error);
  }
};

/**
 * Retrieves the local storage data for the current document.
 * @returns The parsed local storage data of type localStorageType, or undefined if an error occurs.
 */
const getLocalStorage = (): localStorageType => {
  let localStorage: localStorageType;
  try {
    const localStorageStr = window.localStorage.getItem(`${documentId}_patent`);
    localStorage = JSON.parse(localStorageStr);
  } catch (error) {
    console.error(error);
  }
  return localStorage;
};

/**
 * Updates the local storage with new data for the current document.
 * @param localStorage - The new local storage data to be saved.
 */
const updateLocalStorage = (localStorage: localStorageType) => {
  try {
    window.localStorage.setItem(
      `${documentId}_patent`,
      JSON.stringify(localStorage)
    );
  } catch (error) {
    console.error(error);
  }
};

/**
 * Resets the local storage to default values for the current document.
 * Uses the updateLocalStorage function to save the default data.
 * Catches and logs any errors that occur during the process.
 */
const resetLocalStorage = () => {
  try {
    updateLocalStorage(defaultLocalStorage);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Resets a specific item in the local storage to its default value.
 * @param name - The name of the item to reset in the local storage.
 */
const resetItemLocalStorage = (name: string) => {
  try {
    const localStorage = getLocalStorage();
    localStorage[name] = defaultLocalStorage[name];
    updateLocalStorage(localStorage);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Initializes the user-related local storage with default user data.
 */
const initUserLocalStorage = () => {
  try {
    window.localStorage.setItem('patent_user', JSON.stringify(defaultUser));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Retrieves the user data from local storage.
 * @returns The user data of type userType, or undefined if an error occurs.
 */
const getUserLocalStorage = () => {
  let userLocalStorage: userType;
  try {
    const userLocalStorageStr = window.localStorage.getItem('patent_user');
    userLocalStorage = JSON.parse(userLocalStorageStr);
  } catch (error) {
    console.error(error);
  }

  return userLocalStorage;
};

/**
 * Updates the user data in local storage.
 * @param user - The updated user data to be saved.
 */
const updateUserLocalStorage = (user: userType) => {
  try {
    window.localStorage.setItem('patent_user', JSON.stringify(user));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Deletes dynamic definitions from local storage.
 * This function retrieves the current local storage, filters out non-custom definitions,
 * sets all custom definitions as unchecked, and updates the local storage with the new list.
 */
const deleteDynamicDefinitions = () => {
  const localStorage = getLocalStorage();
  const definitions: definitionType[] = [];

  for (let i = 0; i < localStorage.definitions.length; i++) {
    if (localStorage.definitions[i].custom) {
      localStorage.definitions[i].checked = false;
      definitions.push({
        ...localStorage.definitions[i],
      });
    }
  }
  localStorage.definitions = definitions;
  updateLocalStorage(localStorage);
};

/**
 * Toggles the checked status of a definition in local storage.
 * @param definition - The definition object to be updated.
 */
const checkDefinitionLocalStorage = (definition: definitionType) => {
  const localStorage: localStorageType = getLocalStorage();
  const definitions: definitionType[] = localStorage.definitions;

  const indexOfDefinition = definitions.findIndex(
    (item) => item.title === definition.title
  );

  const newDefinitions = definitions;
  newDefinitions.splice(indexOfDefinition, 1, {
    ...definition,
    checked: !definition.checked,
  });

  localStorage.definitions = newDefinitions;
  updateLocalStorage(localStorage);
};

/**
 * Updates the claims in local storage.
 * @param newClaims - An array of strings representing the new claims.
 */
const updateClaims = (newClaims: string[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.claims = newClaims;
  updateLocalStorage(localStorage);
};

/**
 * Updates the claim descriptions in local storage.
 * @param newClaimDescriptions - An array of strings representing the new claim descriptions.
 */
const updateClaimDescriptions = (newClaimDescriptions: string[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.claimDescriptions = newClaimDescriptions;
  updateLocalStorage(localStorage);
};

/**
 * Updates the definitions in the local storage.
 * @param newDefinitions - An array of definition objects to replace the existing definitions in local storage.
 */
const updateDefinitions = (newDefinitions: definitionType[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.definitions = newDefinitions;
  updateLocalStorage(localStorage);
};

/**
 * Updates the references in the local storage.
 * @param newReferences - An array of reference objects to replace the existing references in local storage.
 */
const updateReferences = (newReferences: referenceType[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.references = newReferences;
  updateLocalStorage(localStorage);
};

/**
 * Updates the abstracts in the local storage.
 * @param newAbstracts - An array of abstract objects to replace the existing abstracts in local storage.
 */
const updateAbstracts = (newAbstracts: abstractType[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.abstracts = newAbstracts;
  updateLocalStorage(localStorage);
};

/**
 * Updates the suggestions in the local storage.
 * @param newSuggestions - An array of suggestion objects to replace the existing suggestions in local storage.
 */
const updateSuggestions = (newSuggestions: suggestionType[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.suggestions = newSuggestions;
  updateLocalStorage(localStorage);
};

/**
 * Updates the figures in the local storage.
 * @param newFigures - An array of figure objects to replace the existing figures in local storage.
 */
const updateFigures = (newFigures: figureType[]) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.figures = newFigures;
  updateLocalStorage(localStorage);
};

/**
 * Updates the settings in the local storage.
 * @param newSettings - A settings object to replace the existing settings in local storage.
 */
const updateSettings = (newSettings: settingsType) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.settings = newSettings;
  updateLocalStorage(localStorage);
};

/**
 * Updates the status in the local storage.
 * @param newStatus - A status object to replace the existing status in local storage.
 */
const updateStatus = (newStatus: statusType) => {
  const localStorage: localStorageType = getLocalStorage();

  localStorage.status = newStatus;
  updateLocalStorage(localStorage);
};

/**
 * Retrieves a test object from local storage by its name.
 * @param testName - The name of the test to retrieve.
 * @returns The consistency test object if found, otherwise undefined.
 */
const getTestByName = (testName: string) => {
  const localStorage: localStorageType = getLocalStorage();
  const consistencies: consistencyType[] = localStorage.consistency;

  const test: consistencyType = consistencies.find(
    (test) => test.testName === testName
  );
  return test;
};

/**
 * Retrieves test errors for a specific test from local storage.
 * @param testName - The name of the test to retrieve errors for.
 * @returns An array of test errors (testErrorType[]) associated with the specified test.
 *          Returns an empty array if no errors are found.
 */
const getTestErrors = (testName: string) => {
  const localStorage: localStorageType = getLocalStorage();
  const consistency: consistencyType[] = localStorage.consistency;

  const errors: testErrorType[] =
    consistency.find((test) => test.testName === testName)?.testErrors || [];
  return errors;
};

/**
 * Sets or updates test errors for a specific test in local storage.
 * @param testName - The name of the test to set errors for.
 * @param testTitle - The title of the test.
 * @param errors - An array of test errors (testErrorType[]) to be set for the test.
 */
const setTestErrors = (
  testName: string,
  testTitle: string,
  errors: testErrorType[]
) => {
  const localStorage: localStorageType = getLocalStorage();
  const consistency: consistencyType[] = localStorage.consistency;

  const indexOfTest = consistency.findIndex(
    (test) => test.testName === testName
  );
  if (indexOfTest === -1) {
    consistency.push({
      testName,
      testTitle,
      testErrors: errors,
    });
  } else {
    consistency[indexOfTest].testErrors = errors;
  }

  localStorage.consistency = consistency;
  updateLocalStorage(localStorage);
};

/**
 * Retrieves a specific test error by its ID for a given test.
 * @param testName - The name of the test to search for the error.
 * @param id - The ID of the specific error to retrieve.
 * @returns The test error (testErrorType) matching the given ID, or undefined if not found.
 */
const getTestErrorById = (testName: string, id: string) => {
  const errors = getTestErrors(testName);
  const error = errors.find((error) => error.id === id);
  return error;
};

export {
  initLocalStorage,
  getLocalStorage,
  updateLocalStorage,
  resetLocalStorage,
  resetItemLocalStorage,
  deleteDynamicDefinitions,
  defaultLocalStorage,
  defaultUser,
  userType,
  definitionType,
  referenceType,
  abstractType,
  suggestionType,
  figureType,
  testErrorType,
  consistencyType,
  settingsType,
  statusType,
  localStorageType,
  checkDefinitionLocalStorage,
  initUserLocalStorage,
  getUserLocalStorage,
  updateUserLocalStorage,
  updateClaims,
  updateClaimDescriptions,
  updateDefinitions,
  updateReferences,
  updateAbstracts,
  updateSuggestions,
  updateFigures,
  updateSettings,
  updateStatus,
  getTestErrors,
  getTestByName,
  setTestErrors,
  getTestErrorById,
  setDocumentId,
};
