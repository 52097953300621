/* global Office */

import { redirect } from 'react-router-dom';
import { tempStorage } from './index';
import {
  applyReferences,
  applyAbstracts,
  checkDefinition,
  checkMultipleDefinitions,
  fillAllClaimsDescriptions,
  fillZone,
  finalizeDocumentConsistency,
  generateFigureDescription,
  getDefaultPromiseStatus,
  refreshDefinitions,
  refreshFigures,
  refreshReferences,
  uncheckDefinition,
  uncheckMultipleDefinitions,
  saveDefinitionInDb,
  generateReport,
} from 'engine/addInMethods';
import {
  fetchGetConsistencyList,
  fetchLogin,
  fetchTemplateDocumentContent,
} from 'engine/apiHelpers';
import {
  viewError,
  correctSingleError,
  checkAnyConsistency,
  refreshConsistencyList,
} from 'engine/consistency';
import {
  getDocumentId,
  getDocumentLanguage,
  setDocumentLanguage,
  updateDocumentWithContent,
} from 'engine/documentProxy';
import {
  resetLocalStorage,
  definitionType,
  initLocalStorage,
  setDocumentId,
  testErrorType,
  getLocalStorage,
  initUserLocalStorage,
  getUserLocalStorage,
} from 'engine/localStorageHelper';
import {
  FillZoneType,
  PromiseStatus,
  StatusCodes,
  StatusManager,
} from 'engine/models';
import { UIStrings } from './UIStrings';

const debug = () => {
  console.log('test Word side');
};

/**
 * Creates a common controller object with an abort function for managing promise statuses and status updates.
 *
 * @param statusManager - An object responsible for managing and updating the overall status of operations.
 * @param promiseStatus - An object representing the current status of a promise or operation.
 * @returns An object containing an abort function that can be used to cancel operations and update statuses.
 */
const getCommonController = (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus
) => {
  const abort = () => {
    promiseStatus.aborted = true;
    statusManager.setStatusCode(StatusCodes.Aborted);
    statusManager.setProgress(0);
    statusManager.setStatusMessage(UIStrings.statusbar.canceled);
  };

  const controller = { abort };

  return controller;
};

/**
 * Initializes the document by setting up necessary configurations and storage.
 *
 * This function performs the following tasks:
 * - Resets term-related storage
 * - Retrieves and sets the document ID and language
 * - Initializes local storage if not already present
 *
 * @returns {Promise<void>} A promise that resolves when the initialization is complete
 */
const initDocument = async () => {
  console.log('initDocument');
  console.log('Set API language: ' + tempStorage.apiLanguage);
  tempStorage.totalTerms.length = 0;
  tempStorage.origTerms.length = 0;
  tempStorage.definitionsFirstInsert = true;

  const id = await getDocumentId();
  const lang = await getDocumentLanguage();
  console.log('Get document language: ' + lang);
  tempStorage.apiLanguage = lang ? lang : 'en';
  console.log('Set API language: ' + tempStorage.apiLanguage);
  setDocumentId(id);
  console.log('document id: ' + id);

  const localStorage = getLocalStorage();
  const userLocalStorage = getUserLocalStorage();

  if (!userLocalStorage) {
    initUserLocalStorage();
  }

  if (!localStorage) {
    initLocalStorage();
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
 * Initializes a document with a specified template and language.
 *
 * This function performs the following tasks:
 * - Resets local storage
 * - Fetches and applies the template content
 * - Sets the API language
 * - Updates the document language
 * - Refreshes the consistency list
 *
 * If there's an error fetching the template, it redirects to the index page.
 *
 * @param templateName - The name of the template to be used for initialization
 * @param language - The language code to set for the document and API
 * @returns A Promise that resolves when the initialization is complete
 */
const initDocumentWithTemplate = async (
  templateName: string,
  language: string
) => {
  resetLocalStorage();
  await updateDocumentWithContent('');
  console.log('templateName', templateName);
  const templateResponse = await fetchTemplateDocumentContent(templateName);
  console.log('initDocumentWithTemplate ' + tempStorage);
  if (!templateResponse.error) {
    await updateDocumentWithContent(templateResponse.template);
    console.log('Set API language: ' + tempStorage.apiLanguage);
    tempStorage.apiLanguage = language;
    await setDocumentLanguage(language);
    await refreshConsistencyList();
  } else {
    redirect('/index.html');
  }
};

/**
 * Initiates the process of filling all zones in the document sequentially.
 * This function fills the title, summary top, all claims descriptions, and summary bottom in order.
 *
 * @param statusManager - An object that manages and updates the status of the overall operation.
 *                        It keeps track of progress, task IDs, and other status-related information.
 *
 * @returns A controller object with an abort function that can be used to cancel the operation.
 *          The controller allows external code to abort the filling process if needed.
 */
const fillAllZonesPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  promiseStatus.bulkTaskCount = 4;
  statusManager.currentTaskId = 0;
  statusManager.bulkTaskCount = 4;

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    fillZone(statusManager, FillZoneType.Title, promiseStatus, resolve)
      .then(() => {
        promiseStatus.currentTaskId = 1;
        statusManager.currentTaskId = 1;
        return fillZone(
          statusManager,
          FillZoneType.SummaryTop,
          promiseStatus,
          resolve
        );
      })
      .then(() => {
        promiseStatus.currentTaskId = 2;
        statusManager.currentTaskId = 2;
        return fillAllClaimsDescriptions(statusManager, promiseStatus, resolve);
      })
      .then(() => {
        promiseStatus.currentTaskId = 3;
        statusManager.currentTaskId = 3;
        return fillZone(
          statusManager,
          FillZoneType.SummaryBottom,
          promiseStatus,
          resolve
        );
      });
  });

  return controller;
};

/**
 * Initiates the process of filling all claims descriptions in the document.
 *
 * This function creates a new promise that will execute the fillAllClaimsDescriptions method.
 * It sets up the necessary status management and provides a controller for potential abortion of the process.
 *
 * @param statusManager - An object of type StatusManager that handles updating and managing the overall status of the operation.
 *
 * @returns A controller object with an abort function that can be used to cancel the operation if needed.
 */
const fillAllClaimsDescriptionsPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  statusManager.currentTaskId = 2;

  new Promise((resolve) => {
    fillAllClaimsDescriptions(statusManager, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of filling a specified zone in the document.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param fillZoneType - The type of zone to be filled, as defined in the FillZoneType enum.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const fillZonePromise = (
  statusManager: StatusManager,
  fillZoneType: FillZoneType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    fillZone(statusManager, fillZoneType, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of saving a definition in the database.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param definition - The definition to be saved in the database.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const saveDefinitionInDbPromise = (
  statusManager: StatusManager,
  definition: definitionType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    saveDefinitionInDb(statusManager, definition, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of refreshing definitions.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const refreshDefinitionsPromise = (statusManager: StatusManager) => {
  const promiseStatus: PromiseStatus = {
    progress: 0,
    aborted: false,
    bulkTaskCount: 1,
    currentTaskId: 0,
  };

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshDefinitions(statusManager, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of checking a single definition.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param definition - The definition to be checked.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const checkDefinitionPromise = (
  statusManager: StatusManager,
  definition: definitionType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    checkDefinition(statusManager, definition, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of unchecking a single definition.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param definition - The definition to be unchecked.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const uncheckDefinitionPromise = (
  statusManager: StatusManager,
  definition: definitionType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    uncheckDefinition(statusManager, definition, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of checking multiple definitions.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param definitions - An array of definitions to be checked.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const checkMultipleDefinitionsPromise = (
  statusManager: StatusManager,
  definitions: definitionType[]
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    checkMultipleDefinitions(
      statusManager,
      definitions,
      promiseStatus,
      resolve
    );
  });

  return controller;
};

/**
 * Initiates the process of unchecking multiple definitions.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param definitions - An array of definitions to be unchecked.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const uncheckMultipleDefinitionsPromise = (
  statusManager: StatusManager,
  definitions: definitionType[]
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    uncheckMultipleDefinitions(
      statusManager,
      definitions,
      promiseStatus,
      resolve
    );
  });

  return controller;
};

/**
 * Initiates the process of refreshing references in the document.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const refreshReferencesPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshReferences(statusManager, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of applying references in the document.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const applyReferencesPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    applyReferences(statusManager, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of applying abstracts in the document.
 *
 * This function explicitly awaits the completion of the applyAbstracts method, as otherwise
 * the error corresponding to the unfetched abstracts could not be displayed without reloading.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const applyAbstractsPromise = async (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  await new Promise<void>((resolve) => {
    applyAbstracts(statusManager, promiseStatus, resolve);
  });

  console.log('Finished applying abstracts!');
  return controller;
};

/**
 * Initiates the process of refreshing figures.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const refreshFiguresPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshFigures(statusManager, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of generating figure descriptions and applying references.
 *
 * This function performs two tasks sequentially:
 * 1. Generates figure descriptions
 * 2. Applies references
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const generateFigureDescriptionPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  promiseStatus.bulkTaskCount = 2;
  statusManager.currentTaskId = 0;
  statusManager.bulkTaskCount = 2;

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    generateFigureDescription(statusManager, promiseStatus, resolve).then(
      () => {
        promiseStatus.currentTaskId = 1;
        statusManager.currentTaskId = 1;
        return applyReferences(statusManager, promiseStatus, resolve);
      }
    );
  });

  return controller;
};

/**
 * Initiates the process of finalizing document and generating a report.
 *
 * This function performs two tasks sequentially:
 * 1. Finalizes document consistency
 * 2. Generates a report
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const finalizeDocumentConsistencyPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    finalizeDocumentConsistency(statusManager, promiseStatus, resolve).then(
      () => {
        return generateReport(statusManager, promiseStatus, resolve);
      }
    );
  });

  return controller;
};

/**
 * Initiates the process of rescanning a single test for errors.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param testName - The name of the specific test to be rescanned.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const rescanSingleTestErrorsPromise = (
  statusManager: StatusManager,
  testName: string
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    promiseStatus.bulkTaskCount = 1;
    statusManager.currentTaskId = 0;
    statusManager.bulkTaskCount = 1;
    checkAnyConsistency(statusManager, testName, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of rescanning all tests for errors, either for claims or non-claims based on the claimsStage parameter.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param claimsStage - A boolean indicating whether to check claims (true) or non-claims (false) consistency.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const rescanAllTestsErrorsPromise = (
  statusManager: StatusManager,
  claimsStage: boolean
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();

  const filteredConsistencyList =
    claimsStage === true
      ? tempStorage.consistencyList.filter((c) => c.target === 'claims')
      : tempStorage.consistencyList.filter((c) => c.target !== 'claims');

  promiseStatus.currentTaskId = 0;
  statusManager.currentTaskId = 0;
  promiseStatus.bulkTaskCount = filteredConsistencyList.length;
  statusManager.bulkTaskCount = promiseStatus.bulkTaskCount;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.checking_claims_progress);

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise(async (resolve) => {
    let index = 0;
    for (const consItem of filteredConsistencyList) {
      promiseStatus.currentTaskId = index;
      statusManager.currentTaskId = index;
      try {
        await checkAnyConsistency(
          statusManager,
          consItem.testKey,
          promiseStatus,
          resolve
        );
      } catch (error) {
        console.error(
          `Error occurred while checking consistency for ${consItem.testDisplayName}:`,
          error
        );
        break;
      }
      index++;
    }
    statusManager.setProgress(100);
  });

  return controller;
};

/**
 * Performs an initial refresh of references and definitions in the document.
 *
 * This function initiates a two-step process:
 * 1. Refreshes references
 * 2. Refreshes definitions
 * It updates the status manager throughout the process and provides a controller for potential abortion.
 *
 * @param statusManager - An object of type StatusManager that handles updating and managing the overall status of the operation.
 * @returns A controller object with an abort function that can be used to cancel the operation if needed.
 */
const initialRefresh = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();

  promiseStatus.currentTaskId = 0;
  statusManager.currentTaskId = 0;
  promiseStatus.bulkTaskCount = 2;
  statusManager.bulkTaskCount = 2;

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshReferences(statusManager, promiseStatus, resolve).then(() => {
      promiseStatus.currentTaskId = 1;
      statusManager.currentTaskId = 1;
      return refreshDefinitions(statusManager, promiseStatus, resolve);
    });
  });

  return controller;
};

/**
 * Initiates the process of correcting a single error in the document.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param error - The specific error of type testErrorType to be corrected.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const correctSingleErrorPromise = (
  statusManager: StatusManager,
  error: testErrorType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    correctSingleError(statusManager, error, promiseStatus, resolve);
  });

  return controller;
};

/**
 * Initiates the process of viewing a specific error in the document.
 *
 * @param statusManager - An object that manages and updates the status of the operation.
 * @param error - The specific error of type testErrorType to be viewed.
 * @returns A controller object with an abort function that can be used to cancel the operation.
 */
const viewErrorPromise = (
  statusManager: StatusManager,
  error: testErrorType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    viewError(error, resolve);
  });

  return controller;
};

export {
  debug,
  initDocument,
  initDocumentWithTemplate,
  fillAllClaimsDescriptionsPromise,
  fillZonePromise,
  fillAllZonesPromise,
  saveDefinitionInDbPromise,
  refreshDefinitionsPromise,
  checkDefinitionPromise,
  uncheckDefinitionPromise,
  checkMultipleDefinitionsPromise,
  uncheckMultipleDefinitionsPromise,
  refreshReferencesPromise,
  applyReferencesPromise,
  applyAbstractsPromise,
  refreshFiguresPromise,
  generateFigureDescriptionPromise,
  finalizeDocumentConsistencyPromise,
  rescanSingleTestErrorsPromise,
  rescanAllTestsErrorsPromise,
  initialRefresh,
  correctSingleErrorPromise,
  viewErrorPromise,
};
