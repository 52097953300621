import { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

import { applyAbstractsPromise } from 'app/office-document';
import { StatusCodes, StatusManager } from 'engine/models';
import {
  getLocalStorage,
  abstractType,
  updateAbstracts,
} from 'engine/localStorageHelper';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Abstracts/Abstracts.scss';
import Progress from 'components/Progress/Progress';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import AbstractItem from 'components/AbstractItem/AbstractItem';
import { UIStrings } from 'app/UIStrings';

const Abstract = () => {
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(false);
  const [abstracts, setAbstracts] = useState<abstractType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<'refresh' | 'apply'>(null);

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  /**
   * A side effect that updates progress when the status code is successful.
   */
  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  /**
   * Updates the progress status based on the type of action performed.
   *
   * @param type - The type of action, either 'refresh' or 'apply'.
   * @returns A function that updates the status state.
   */
  const updateProgress = (type: 'refresh' | 'apply') => {
    if (type === 'apply') {
      setStatus((prevStatus) => {
        if (abstracts.length === 0) {
          return { ...prevStatus, abstracts: 0 }; // No abstracts: set to 0
        } else {
          return { ...prevStatus, abstracts: 100 }; // Abstracts are present and being applied: set to 100
        }
      });
    } else if (type === 'refresh') {
      setStatus((prevStatus) => {
        if (abstracts.length > 0) {
          return { ...prevStatus, abstracts: 50 }; // Abstracts are present but not applied: set to 50
        } else {
          return { ...prevStatus, abstracts: 0 }; // No abstracts upon refresh
        }
      });
    }
  };

  /**
   * Initializes the component state with abstracts from local storage.
   * This effect runs once when the component mounts.
   */
  useEffect(() => {
    const localStorage = getLocalStorage();
    const abstracts = localStorage.abstracts;

    if (abstracts.length > 0) {
      setAbstracts(localStorage.abstracts);
    }
  }, []);

  /**
   * Fetches the abstracts of the given patent numbers, then adds them to the document.
   * This function triggers the process to add abstracts to the document and updates the type of action.
   */
  const addToDocument = async () => {
    console.log('add abstracts to document');
    await applyAbstractsPromise(statusManager); // Now valid because the function is async

    const updatedAbstracts = getLocalStorage().abstracts;

    // Update both local storage and state
    setAbstracts(updatedAbstracts);
    updateAbstracts(updatedAbstracts); // If this function is meant to persist them back
    setTypeOfAction('apply');
  };

  // TODO
  const abortHandler = () => {
    console.log('abort');
  };

  /**
   * Creates a new custom abstract and adds it to the existing abstracts.
   *
   * @param title - The title of the new abstract to be created.
   */
  const createCustomAbstract = (patentNumber: string) => {
    console.log('create custom abstract', patentNumber);

    const newAbstracts: abstractType[] = [
      ...abstracts,
      {
        id: `${crypto.randomUUID()}`,
        patentNumber: patentNumber.trim(),
      },
    ];

    updateAbstractsInLocalStorage(newAbstracts);
    setTypeOfAction('refresh'); // Trigger refresh to update status
  };

  /**
   * Deletes a abstract from the abstracts list.
   *
   * @param referenceToDelete - The abstract to be deleted.
   */
  const deleteAbstract = (abstractToDelete: abstractType) => {
    console.log('delete abstract', abstractToDelete);

    const newAbstracts: abstractType[] = abstracts.filter(
      (abstract) => abstract.id !== abstractToDelete.id
    );

    updateAbstractsInLocalStorage(newAbstracts);
    setTypeOfAction('refresh'); // Trigger refresh to update status
  };

  /**
   * Updates a specific abstract in the local storage.
   *
   * @param abstractToUpdate - The abstract object to be updated.
   * @param newPatentNumber - The new patent number.
   */
  const updateAbstractInLocalStorage = (
    abstractToUpdate: abstractType,
    newPatentNumber: string
  ) => {
    console.log(
      'update abstract in local storage',
      newPatentNumber,
    );

    const indexOfAbstract = abstracts.findIndex(
      (abstract) => abstract.id === abstractToUpdate.id
    );
    const newAbstracts = abstracts;
    newAbstracts.splice(indexOfAbstract, 1, {
      id: abstractToUpdate.id,
      patentNumber: newPatentNumber.trim(),
    });

    updateAbstractsInLocalStorage(newAbstracts);
  };

  /**
   * Updates the abstracts in the local storage and the component state.
   *
   * @param newAbstracts - The new array of abstracts to be stored.
   */
  const updateAbstractsInLocalStorage = (newAbstracts: abstractType[]) => {
    setAbstracts(newAbstracts);
    updateAbstracts(newAbstracts);
  };

  /**
   * Handles the end of a drag operation for abstract items.
   *
   * @param event - The drag end event object containing information about the drag operation.
   * @param event.active - The active (dragged) item.
   * @param event.over - The item over which the active item was dropped.
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const oldIndex = abstracts.findIndex(
      (abstract) => abstract.id === active.id
    );
    const newIndex = abstracts.findIndex((abstract) => abstract.id === over.id);
    const newAbstracts: abstractType[] = arrayMove(
      abstracts,
      oldIndex,
      newIndex
    );

    setAbstracts(newAbstracts);
    updateAbstracts(newAbstracts);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.abstracts.abstracts_title}
          description={UIStrings.abstracts.abstracts_description}
          color="black"
          hasProgress={true}
          progress={status.abstracts}
        />

        <div className={classes.main}>
          <div>
            <div className={classes.titleWrapper}>Patentnummer-Liste</div>

            {abstracts.length > 0 || isNew ? (
              <div className={classes.abstractList}>
                <DndContext onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={abstracts}
                    strategy={verticalListSortingStrategy}
                  >
                    {abstracts.map((abstract: abstractType) => (
                      <AbstractItem
                        key={`${abstract.id}.${abstract.patentNumber}`}
                        abstract={abstract}
                        deleteAbstract={deleteAbstract}
                        updateAbstractInLocalStorage={updateAbstractInLocalStorage}
                        hasError={abstract.hasError || false} // Pass error state
                      />
                    ))}

                    {isNew && (
                      <AbstractItem
                        abstract={{
                          id: '',
                          patentNumber: '',
                        }}
                        isNew={isNew}
                        cancel={() => setIsNew(false)}
                        createCustomAbstract={createCustomAbstract}
                      />
                    )}
                  </SortableContext>
                </DndContext>

                <Button
                  title={UIStrings.abstracts.abstracts_add}
                  color="reference"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            ) : (
              <div className={classes.emptyList}>
                <div>
                  {UIStrings.abstracts.abstracts_empty}
                </div>
                <Button
                  title={UIStrings.abstracts.abstracts_add}
                  color="transparent"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            )}
          </div>
        </div>

        <div className={classes.addToDocumentButton}>
          <Button
            title={UIStrings.abstracts.add_to_document}
            color="blue"
            size="big"
            onClick={addToDocument}
          />
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default Abstract;
