// extracted by mini-css-extract-plugin
var _1 = "ppi0nV_A4ZAcSxxdrIk7";
var _2 = "qsIHeC6S9JOLorvuxR8V";
var _3 = "ptbMrGivrWk8waRHQTfQ";
var _4 = "_ZkiP6P5bXnE9JBGqMAb";
var _5 = "IxerbV0axPYTT4v_ysgP";
var _6 = "CNcyQlZAr3Do4nKGkBsr";
var _7 = "aoPBF9J2bSfqfe2ffMGg";
var _8 = "ZIwe9AbNFf5L7xgsvYMR";
var _9 = "Gcy20LInZktG996ZqbW5";
var _a = "_9KmVsOmfvUS4zMEhmup";
export { _1 as "abstractsIcon", _2 as "consistencyIcon", _3 as "definitionIcon", _4 as "figuresIcon", _5 as "generationIcon", _6 as "icon", _7 as "link", _8 as "referenceIcon", _9 as "settingsIcon", _a as "statusBadge" }
