import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

import {
  applyReferencesPromise,
  refreshReferencesPromise,
  initialRefresh,
} from 'app/office-document';
import { StatusCodes, StatusManager } from 'engine/models';
import {
  getLocalStorage,
  referenceType,
  suggestionType,
  updateReferences,
  updateSuggestions,
} from 'engine/localStorageHelper';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Reference/Reference.scss';
import Progress from 'components/Progress/Progress';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import ReferenceItem from 'components/ReferenceItem/ReferenceItem';
import { UIStrings } from 'app/UIStrings';

const Reference = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(false);
  const [references, setReferences] = useState<referenceType[]>([]);
  const [suggestions, setSuggestions] = useState<suggestionType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<'refresh' | 'apply'>(null);

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success) {
        const suggestions = getLocalStorage().suggestions;
        setSuggestions(suggestions);
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  /**
   * A side effect that triggers a refresh when navigating from the 'consistency-claims' path.
   */
  useEffect(() => {
    if (location.state) {
      const prevPath = location.state.prevPath;
      if (prevPath === 'consistency-claims') {
        refresh();
      }
    }
  }, []);

  /**
   * A side effect that updates progress when the status code is successful.
   */
  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  /**
   * Updates the progress status based on the type of action performed.
   *
   * @param type - The type of action, either 'refresh' or 'apply'.
   * @returns A function that updates the status state.
   */
  const updateProgress = (type: 'refresh' | 'apply') => {
    if (type === 'refresh') {
      return setStatus((prevStatus) => {
        if (prevStatus.references === 100) {
          return prevStatus;
        }

        if (prevStatus.references === 50 && suggestions.length > 0) {
          return prevStatus;
        }

        return { ...prevStatus, references: prevStatus.references + 50 };
      });
    }

    if (type === 'apply') {
      return setStatus((prevStatus) => {
        if (prevStatus.references === 100) {
          return prevStatus;
        }

        if (references.length === 0) {
          return prevStatus;
        }

        return { ...prevStatus, references: prevStatus.references + 50 };
      });
    }
  };

  /**
   * Initializes the component state with references and suggestions from local storage.
   * This effect runs once when the component mounts.
   */
  useEffect(() => {
    const localStorage = getLocalStorage();
    const references = localStorage.references;
    const suggestions = localStorage.suggestions;

    if (references.length > 0) {
      setReferences(references);
    }

    if (suggestions.length > 0) {
      setSuggestions(suggestions);
    }
  }, []);

  /**
   * Initiates a refresh operation for references.
   * This function triggers the initial refresh process and updates the type of action.
   */
  const refresh = () => {
    initialRefresh(statusManager);
    setTypeOfAction('refresh');
  };

  /**
   * Applies the current references to the document.
   * This function triggers the process to add references to the document and updates the type of action.
   */
  const addToDocument = () => {
    applyReferencesPromise(statusManager);
    setTypeOfAction('apply');
  };

  // TODO: Implement the abort method.
  const abortHandler = () => {};

  /**
   * Renumbers all references sequentially.
   * This function creates a new array of references with updated reference numbers
   * and updates the local storage with the new references.
   */
  const renumberReferences = () => {
    const newReferences: referenceType[] = [];
    for (let i = 1; i <= references.length; i++) {
      newReferences.push({
        ...references[i - 1],
        referenceNumber: i.toString(),
      });
    }

    updateReferencesInLocalStorage(newReferences);
  };

  /**
   * Creates a new custom reference and adds it to the existing references.
   * 
   * @param title - The title of the new reference to be created.
   */
  const createCustomReference = (title: string) => {
    const newReferences: referenceType[] = [
      ...references,
      {
        id: `${crypto.randomUUID()}`,
        title: title.trim(),
        rootWord: '',
        searchWholePhrase: false,
        leftPhrase: '',
        referenceNumber: getNewReferenceNumber(),
      },
    ];

    updateReferencesInLocalStorage(newReferences);
  };

  /**
   * Creates or updates a custom suggestion based on the provided title.
   * If the suggestion already exists, it updates it. Otherwise, it adds a new suggestion.
   * 
   * @param title - The title of the suggestion to be created or updated.
   */
  const createCustomSuggestion = (title: string) => {
    if (!title.trim()) return;

    const newSuggestions: suggestionType[] = suggestions;
    const indexOfSuggestion = suggestions.findIndex(
      (item) => item.title === title
    );

    if (indexOfSuggestion >= 0) {
      newSuggestions.splice(indexOfSuggestion, 1, {
        title: title.trim(),
        enabled: true,
      });
    } else {
      newSuggestions.push({
        title: title.trim(),
        enabled: true,
      });
    }

    updateSuggestionsInLocalStorage(newSuggestions);
  };

  /**
   * Adds a suggestion to the references list and removes it from the suggestions list.
   * 
   * @param suggestionToAdd - The suggestion to be added as a reference.
   */
  const addSuggestionReference = (suggestionToAdd: suggestionType) => {
    const newSuggestions: suggestionType[] = suggestions.filter(
      (suggestion) => suggestion.title !== suggestionToAdd.title
    );

    createCustomReference(suggestionToAdd.title);
    updateSuggestionsInLocalStorage(newSuggestions);
  };

  /**
   * Deletes a reference from the references list and adds it to the suggestions list.
   * 
   * @param referenceToDelete - The reference to be deleted.
   */
  const deleteReference = (referenceToDelete: referenceType) => {
    const newReferences: referenceType[] = references.filter(
      (reference) => reference.id !== referenceToDelete.id
    );

    createCustomSuggestion(referenceToDelete.title);
    updateReferencesInLocalStorage(newReferences);
  };

  /**
   * Updates a specific reference in the local storage.
   * 
   * @param referenceToUpdate - The reference object to be updated.
   * @param newTitle - The new title for the reference.
   * @param newReferenceNumber - The new reference number.
   */
  const updateReferenceInLocalStorage = (
    referenceToUpdate: referenceType,
    newTitle: string,
    newReferenceNumber: string
  ) => {
    const indexOfReference = references.findIndex(
      (reference) => reference.id === referenceToUpdate.id
    );
    const newReferences = references;
    newReferences.splice(indexOfReference, 1, {
      id: referenceToUpdate.id,
      title: newTitle.trim(),
      rootWord: '',
      searchWholePhrase: false,
      leftPhrase: '',
      referenceNumber: newReferenceNumber,
    });

    updateReferencesInLocalStorage(newReferences);
  };

  /**
   * Updates the references in the local storage and the component state.
   * 
   * @param newReferences - The new array of references to be stored.
   */
  const updateReferencesInLocalStorage = (newReferences: referenceType[]) => {
    setReferences(newReferences);
    updateReferences(newReferences);
  };

  /**
   * Updates the suggestions in the local storage and the component state.
   * 
   * @param newSuggestions - The new array of suggestions to be stored.
   */
  const updateSuggestionsInLocalStorage = (
    newSuggestions: suggestionType[]
  ) => {
    setSuggestions(newSuggestions);
    updateSuggestions(newSuggestions);
  };

  /**
   * Checks if a given reference number already exists in the references array.
   * 
   * @param refNumber - The reference number to check for duplicates.
   * @returns A boolean indicating whether the reference number is a duplicate (true) or not (false).
   */
  const checkDuplicateReference = (refNumber: string) => {
    const indexOfDuplicateReference = references.findIndex(
      (reference) => reference.referenceNumber === refNumber
    );

    return indexOfDuplicateReference > -1 && references.length > 1;
  };

  /**
   * Generates a new reference number based on the existing references.
   * 
   * @returns A string representing the new reference number.
   */
  const getNewReferenceNumber = () => {
    if (references.length === 0) return '1';

    const numberPattern = /\d+/g;

    const formattedReferences = references.map((referenceItem) => {
      const onlyNumbers = referenceItem.referenceNumber.match(numberPattern);
      const formattedReferenceNumber = onlyNumbers ? onlyNumbers.join('') : '0';

      return {
        ...referenceItem,
        referenceNumber: formattedReferenceNumber,
      };
    });

    const maxReferenceNumber =
      Math.max(
        ...formattedReferences.map(
          (referenceItem: referenceType) => +referenceItem.referenceNumber
        )
      ) + 1;

    return maxReferenceNumber.toString();
  };

  /**
   * Handles the end of a drag operation for reference items.
   * 
   * @param event - The drag end event object containing information about the drag operation.
   * @param event.active - The active (dragged) item.
   * @param event.over - The item over which the active item was dropped.
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const oldIndex = references.findIndex(
      (reference) => reference.id === active.id
    );
    const newIndex = references.findIndex(
      (reference) => reference.id === over.id
    );
    const newReferences: referenceType[] = arrayMove(
      references,
      oldIndex,
      newIndex
    );

    setReferences(newReferences);
    updateReferences(newReferences);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.references.references_title}
          description={UIStrings.references.references_description}
          color="black"
          hasProgress={true}
          progress={status.references}
          refresh={refresh}
        />

        <div className={classes.main}>
          <div>
            <div className={classes.titleWrapper}>
              {UIStrings.references.references_title}
              <div onClick={renumberReferences} className={classes.renumber}>
                <div
                  className={`${classes.icon} ${classes.renumberIcon}`}
                ></div>
                {UIStrings.references.references_renumber}
              </div>
            </div>

            {references.length > 0 || isNew ? (
              <div className={classes.referenceList}>
                <DndContext onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={references}
                    strategy={verticalListSortingStrategy}
                  >
                    {references.map((reference: referenceType) => (
                      <ReferenceItem
                        key={`${reference.id}.${reference.title}.${reference.referenceNumber}`}
                        reference={reference}
                        deleteReference={deleteReference}
                        updateReferenceInLocalStorage={
                          updateReferenceInLocalStorage
                        }
                        checkDuplicateReference={checkDuplicateReference}
                      />
                    ))}

                    {isNew && (
                      <ReferenceItem
                        reference={{
                          id: '',
                          title: '',
                          rootWord: '',
                          searchWholePhrase: false,
                          leftPhrase: '',
                          referenceNumber: getNewReferenceNumber(),
                        }}
                        isNew={isNew}
                        cancel={() => setIsNew(false)}
                        createCustomReference={createCustomReference}
                      />
                    )}
                  </SortableContext>
                </DndContext>

                <Button
                  title={UIStrings.references.references_add}
                  color="reference"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            ) : (
              <div className={classes.emptyList}>
                <div>{UIStrings.references.references_empty}</div>
                <Button
                  title={UIStrings.references.references_add}
                  color="transparent"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            )}
          </div>

          <div>
            <div className={classes.titleWrapper}>
              {UIStrings.references.suggestions_title}
            </div>
            <div className={classes.suggestionList}>
              {suggestions.length > 0 &&
                suggestions.map(
                  (suggestion: suggestionType, index: number) =>
                    suggestion.enabled && (
                      <div
                        key={suggestion.title + index}
                        className={classes.suggestionItem}
                      >
                        <div>{suggestion.title}</div>
                        <div
                          className={`${classes.icon} ${classes.addIcon}`}
                          onClick={() => addSuggestionReference(suggestion)}
                        ></div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>

        <div className={classes.addToDocumentButton}>
          <Button
            title={UIStrings.references.add_to_document}
            color="blue"
            size="big"
            onClick={addToDocument}
          />
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default Reference;
