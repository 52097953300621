import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { statusType, updateStatus } from 'engine/localStorageHelper';
import { StatusContext } from 'app/index';
import { AuthProvider } from 'app/auth';
import * as classes from 'views/App/App.scss';
import NavigationBar from 'components/NavigationBar/NavigationBar';

export type RouterContextType = {
  status: statusType;
  setStatus: Dispatch<SetStateAction<statusType>>;
};

const App = () => {
  const location = useLocation();

  const contextStatus = useContext(StatusContext);
  const [status, setStatus] = useState<statusType>(contextStatus);

  const isNavigationBarVisible = () => {
    const isAuthenticated = AuthProvider.isAuthenticated;

    return (
      isAuthenticated &&
      location.pathname !== '/index.html/template' &&
      location.pathname !== '/index.html/final' &&
      location.pathname !== '/index.html/patent-formula' &&
      location.pathname !== '/index.html/consistency-claims'
    );
  };

  useEffect(() => {
    updateStatus(status);
  }, [status]);
/**
 * Updates the status in local storage whenever the status state changes.
 * This effect ensures that the latest status is always persisted.
 *
 * @effect
 * @param {Function} callback - The effect function that updates the status.
 * @param {statusType[]} dependencies - An array containing the status state.
 * @returns {void}
 */
useEffect(() => {
  updateStatus(status);
}, [status]);

  return (
    <div className={classes.container}>
      <Outlet context={{ status, setStatus }} />
      {isNavigationBarVisible() && <NavigationBar status={status} />}
    </div>
  );
};

export default App;
