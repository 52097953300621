import { useState } from 'react';
import { Tooltip } from '@fluentui/react-components';

import { figureType } from 'engine/localStorageHelper';
import * as classes from 'components/FigureItem/FigureItem.scss';
import { UIStrings } from 'app/UIStrings';

type FigureItemProps = {
  figure: figureType;
  claims: string[];
  isNew?: boolean;
  close?: () => void;
  createCustomFigure?: (title: string) => void;
  deleteFigure?: (figure: figureType) => void;
  updateFigure?: (
    figure: figureType,
    newTitle: string,
    newDescription: string,
    newClaimIds: number[]
  ) => void;
};

const FigureItem = ({
  figure,
  claims,
  isNew = false,
  close,
  createCustomFigure,
  deleteFigure,
  updateFigure,
}: FigureItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [title, setTitle] = useState<string>(figure.title);
  const [description, setDescription] = useState<string>(figure.description);
  const [claimIds, setClaimIds] = useState<number[]>(figure.claimIds);

  /**
   * Creates a new figure with the current title.
   * If the title is empty (after trimming), the function returns without doing anything.
   */
  const handleCreateFigure = () => {
    if (!title.trim()) return;

    close();
    createCustomFigure(title);
  };

  /**
   * Updates the figure in local storage with the current title, description, and claim IDs.
   * If the title is empty (after trimming), the function returns without doing anything.
   */
  const handleUpdateFigureInLocalStorage = () => {
    if (!title.trim()) return;

    updateFigure(figure, title, description, claimIds);
    setTitle(title.trim());
    setDescription(description.trim());
  };

  /**
   * Toggles the selection of a claim for the current figure and updates the figure in local storage.
   * @param {React.MouseEvent} e - The mouse event object.
   * @param {number} claimId - The ID of the claim to be toggled.
   */
  const handleUpdateClaimIds = (e: React.MouseEvent<HTMLDivElement>, claimId: number) => {
    const indexOfClaimId = claimIds.indexOf(claimId);
    const newClaimIds = [...claimIds];

    if (indexOfClaimId >= 0) {
      (e.currentTarget as HTMLDivElement).style.backgroundColor = '#FFF';
      (e.currentTarget as HTMLDivElement).style.color = '#020305';
      newClaimIds.splice(indexOfClaimId, 1);
    } else {
      (e.currentTarget as HTMLDivElement).style.backgroundColor = '#1267B4';
      (e.currentTarget as HTMLDivElement).style.color = '#FFF';
      newClaimIds.push(claimId);
    }

    setClaimIds(newClaimIds);
    handleUpdateFigureInLocalStorage();
  };

  return (
    <div className={classes.container}>
      {isNew ? (
        <div className={classes.titleWrapper}>
          <input
            type="text"
            id="title"
            className={classes.titleInput}
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div className={classes.options}>
            <div
              className={`${classes.icon} ${classes.dismissIcon}`}
              onClick={close}
            ></div>
            <div
              className={`${classes.icon} ${classes.applyIcon}`}
              onClick={handleCreateFigure}
            ></div>
          </div>
        </div>
      ) : (
        <div className={classes.titleWrapper}>
          <input
            type="text"
            id="title"
            className={classes.titleInput}
            value={title}
            onBlur={handleUpdateFigureInLocalStorage}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div className={classes.options}>
            <div
              className={`${classes.icon} ${classes.trashIcon}`}
              onClick={() =>
                deleteFigure({
                  id: figure.id,
                  title,
                  description,
                  claimIds: [],
                  complete: figure.complete,
                })
              }
            ></div>
            {isOpen ? (
              <div
                className={`${classes.icon} ${classes.arrowDown}`}
                onClick={() => setIsOpen(!isOpen)}
              ></div>
            ) : (
              <div
                className={`${classes.icon} ${classes.arrowUp}`}
                onClick={() => setIsOpen(!isOpen)}
              ></div>
            )}
          </div>
        </div>
      )}

      {isOpen && (
        <div className={classes.descriptionWrapper}>
          <textarea
            placeholder={UIStrings.figure_item.figure_description_placeholder}
            value={description}
            onBlur={handleUpdateFigureInLocalStorage}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>

          {claims.length > 0 && (
            <div className={classes.claimsWrapper}>
              {claims.map((claim, index) => (
                <Tooltip
                  key={index}
                  content={claim}
                  relationship="description"
                  positioning="below"
                  appearance="inverted"
                >
                  <div
                    className={classes.claimItem}
                    onClick={(e) => handleUpdateClaimIds(e, index)}
                    style={{
                      color: claimIds.includes(index) ? '#FFFFFF' : '#020305',
                      backgroundColor: claimIds.includes(index)
                        ? '#1267B4'
                        : '#FFFFFF',
                    }}
                  >
                    {index + 1}
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FigureItem;
